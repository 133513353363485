import api from './api'

const FactoryService = {

    getFactorybyID (factory_id) {
        return api.get(`factory/get_factory/${factory_id}`)
    },
    getAllFactories () {
        return api.get(`factory/get_all_factories`)
    },


}

export default FactoryService;