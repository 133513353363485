import api from "./api";

const AreaService = {
  getAreaDashboard(payload) {
    return api.put(`area/get_area_dashboard`, payload);
  },
  getRecentAlerts(payload) {
    return api.put(`area/get_recent_alerts`, payload);
  },
  getHelmetModule(payload) {
    return api.put(`area/get_helmet_dashboard`, payload);
  },
  getLiveCameras(payload) {
    return api.put(`area/get_live_cameras`, payload);
  },
  getWeeklyAlerts(payload) {
    return api.put(`area/get_weekly_alerts`, payload);
  },
  getMonthlyAlerts(payload) {
    return api.put(`area/get_monthly_alerts`, payload);
  },
  getDurationAlerts(payload) {
    return api.put(`area/get_duration_alerts`, payload);
  },
  getAlltickets() {
    return api.get(`/tickets/get_user_tickets/${JSON.parse(localStorage.getItem("userData"))?.id || 41}`);
  },
  generatenewticket(payload) {
    return api.post("tickets/add_ticket", payload);
  },
  editticket(payload) {
    return api.put("tickets/edit_ticket", payload);
  },
  getFilterAlerts(payload) {
    return api.put("/area/get_live_alerts", payload);
  },
  getDataforItTickets() {
    return api.get(`/tickets/get_all_tickets`);
  },
  handleTicketUpdateReq(payload) {
    return api.put(`/tickets/update_ticket_status`, payload);
  },
  getAreaUserTickets() {
    return api.get(`/tickets/get_user_tickets/${41}`);
  },
  getAlertsChart(payload) {
    return api.put("compliance/compliance_summary", payload);
  },
  getAiReportsCards(payload) {
    return api.put(
      "compliance_by_camera/compliance_by_camera_summary",
      payload
    );
  },
  getApprovalAlerts(payload) {
    return api.put("compliance_accuracy/compliance_accuracy", payload);
  },
  getModelAccuracyChart(payload) {
    return api.put(
      "/compliance_accuracy_fetch/compliance_accuracy_fetch",
      payload
    );
  },
  getOperationID(payload) {
    return api.put(
      "/compliance_accuracy_operation_ids/compliance_accuracy_operation_ids",
      payload
    );
  },
  getAllUserForit() {
    return api.get("/users/get_all_Users");
  },
  deleteUserforIT(id) {
    return api.delete(`/users/delete_user/${id}`);
  },
  updateUserStatusIT(id) {
    return api.put(`/users/update_user_status/${id}`);
  },
  getAllFactoriesDataForIT() {
    return api.get("/factory/get_all_factories");
  },
  AdduserIT(payload) {
    return api.post("/users/add_user", payload);
  },
  fetchAllRolesforIT() {
    return api.get("/users/get_all_roles");
  },
  fetchAnalyticsPercentGbl() {
    return api.put("compliance_week_percentage/compliance_week_percentage");
  },
  fetchAnalyticsProgressGbl() {
    return api.put("compliance_gadget_percentage/compliance_gadget_percentage");
  },
  fetchAnalyticsHeatmapGbl() {
    return api.put("live_analytics_heatmap/live_analytics_heatmap");
  },
  AuthLogin(payload) {
    return api.post("users/login_with_email", payload);
  },
  updateUser(payload) {
    return api.put("users/update_user", payload);
  },
  AuthLogin(payload) {
    return api.post("users/login_with_email", payload);
  },
  fetchCameraConnectivity(id) {
    return api.get(`cameras/get_user_cameras_count/${id}`);
  },
  fetchDataForLeaderBoard(payload){
    return api.put('get_leader_board_data/get_leader_board_data',payload);
  }

};

export default AreaService;