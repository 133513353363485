import React, { useState } from 'react';
import { Container, Card, CardBody, Col, Row, Input } from 'reactstrap';
import search from '../../../../../assets/SuperAdminIcons/Search.png';
import ItDataTable from '../Components/Table/ItDataTable';
import { Link } from 'react-router-dom';
import { H3, H4 } from '../../../../../AbstractElements';
import DetailsButton from '../../../../Common/newButton/index'
import SearchInput from '../../../../Common/Search Input/searchInput'
import '../Styling/itStyle.css'
const ItLogs = () => {
    const [filters, setFilters] = useState({
        creator: '',
        status: ''
    })
  
    const tableData = [
        {
            id: 12,
            status: 'Active',
            createdBy: 'Super Admin',
            name: "John Doe",
            email: "john.doe@example.com",
            role: "IT Officer",
            date: "2024-08-01",
            action: "Approved",
            Phone: "+92313778899",
            permissions: [
                { name: 'ITOfficer', isActive: false },
                { name: 'Global', isActive: true },
                { name: 'Factory', isActive: false },
                { name: 'Area', isActive: true },
                { name: 'TechQA', isActive: false }
            ],
            LockoutDuration: '10 min',
            LockoutTime: 'last active 1 day ago',
        },
        {
            id: 27,
            status: 'Active',
            createdBy: 'It Officer',
            name: "Jane Smith",
            email: "jane.smith@example.com",
            Phone: "+92313778899",
            role: "Global",
            date: "2024-08-02",
            action: "Pending",
            permissions: [
                { name: 'ITOfficer', isActive: false },
                { name: 'Global', isActive: true },
                { name: 'Factory', isActive: true },
                { name: 'Area', isActive: false },
                { name: 'TechQA', isActive: false }
            ],
            LockoutDuration: '5 min',
            LockoutTime: 'last active 3 hours ago',
        },
        {
            id: 30,
            status: 'Inactive',
            createdBy: 'It Officer',
            name: "Michael Johnson",
            email: "michael.johnson@example.com",
            Phone: "+92313778899",
            role: "Factory",
            date: "2024-08-03",
            action: "Rejected",
            permissions: [
                { name: 'ITOfficer', isActive: true },
                { name: 'Global', isActive: true },
                { name: 'Factory', isActive: true },
                { name: 'Area', isActive: false },
                { name: 'TechQA', isActive: true }
            ],
            LockoutDuration: '23 min',
            LockoutTime: 'last active 3 day ago',
        },
        {
            id: 29,
            status: 'Active',
            createdBy: 'It Officer',
            name: "Emily Davis",
            Phone: "+92313778899",
            email: "emily.davis@example.com",
            role: "Area",
            date: "2024-08-04",
            action: "Approved",
            permissions: [
                { name: 'ITOfficer', isActive: true },
                { name: 'Global', isActive: true },
                { name: 'Factory', isActive: false },
                { name: 'Area', isActive: true },
                { name: 'TechQA', isActive: false }
            ],
            LockoutDuration: '25 min',
            LockoutTime: 'last active 8 hours ago',
        },
        {
            id: 33,
            status: 'Inactive',
            createdBy: 'It Officer',
            name: "William Brown",
            email: "william.brown@example.com",
            role: "Tech QA",
            Phone: "+92313778899",
            date: "2024-08-05",
            action: "Pending",
            permissions: [
                { name: 'ITOfficer', isActive: false },
                { name: 'Global', isActive: true },
                { name: 'Factory', isActive: true },
                { name: 'Area', isActive: false },
                { name: 'TechQA', isActive: false }
            ],
            LockoutDuration: '13 min',
            LockoutTime: 'last active 6 day ago',
        },
        {
            id: 21,
            status: 'Active',
            createdBy: 'It Officer',
            name: "William Brown",
            Phone: "+92313778899",
            email: "william.brown@example.com",
            role: "Area",
            date: "2024-08-05",
            action: "Pending",
            permissions: [
                { name: 'ITOfficer', isActive: true },
                { name: 'Global', isActive: false },
                { name: 'Factory', isActive: true },
                { name: 'Area', isActive: true },
                { name: 'TechQA', isActive: false }
            ],
            LockoutDuration: '45 min',
            LockoutTime: 'last active 3 day ago',
        },
        {
            id: 36,
            status: 'Inactive',
            createdBy: 'It Officer',
            name: "William Brown",
            email: "william.brown@example.com",
            Phone: "+92313778899",
            role: "Area",
            date: "2024-08-05",
            action: "Pending",
            permissions: [
                { name: 'ITOfficer', isActive: true },
                { name: 'Global', isActive: false },
                { name: 'Factory', isActive: true },
                { name: 'Area', isActive: true },
                { name: 'TechQA', isActive: false }
            ],
            LockoutDuration: '29 min',
            LockoutTime: 'last active 1 hour ago',
        }
    ]
    const tableColumns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            // sortable: true,
        },
        {
            name: 'Created By',
            selector: row => row.createdBy,
        },
        {
            name: 'Status',
            selector: row => row.status,
        },
        {
            name: 'Lockout Time',
            selector: row => row.LockoutTime,
            // sortable: true,
        },
        {
            name: 'Lockout Duration',
            selector: row => row.LockoutDuration,
            // sortable: true,
        },
        {
            name: 'Details',
            cell: row => (
                <Link className='m-1' to={`${process.env.PUBLIC_URL}/dashboard/Detaillogs/${JSON.parse(localStorage.getItem('role'))}`}>
                   <DetailsButton btnText='Details' />
                </Link>
            ),
            // sortable: true,
        },
    ]
    const [showDetails, setshowDetails] = useState(false)
    const handleDetails = () => {
        setshowDetails(!showDetails);
    }
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState(undefined); 
    const handleSearch = (e) => {
        // const value = e.target.value.toLowerCase();
        // setSearchTerm(value);

        const value = e.target.value.trim().toLowerCase();
        if (!e.target.value.trim()) {
            filterDataa(filters);  // Reapply the button filters when search input is cleared
            return;
        }

        const filterVar = filteredData ? filteredData : tableData
         
        const filtered = filterVar.filter(item =>
            item.name.toLowerCase().includes(value) ||
            item.email.toLowerCase().includes(value) ||
            item.role.toLowerCase().includes(value) ||
            item.status.toLowerCase().includes(value) ||
            item.createdBy.toLowerCase().includes(value)
        );
        setFilteredData(filtered);
    };
    const handleFilterChange = (e, field) => {
        setFilters((prev) => ({
            ...prev,
            [field]: e.target.value
        }))
        filterDataa({ ...filters, [field]: e.target.value })
        // setDataAfterFilteration(fil);
    }

    const filterDataa = (currentFilters) => {
        let filteredData = tableData;
        if (currentFilters.creator) {
            filteredData = filteredData.filter((item) => item.createdBy === currentFilters.creator);
        }
        if (currentFilters.status) {
            filteredData = filteredData.filter((item) => item.status === currentFilters.status);
        }

        setFilteredData(filteredData);
    };

    const creaters = tableData && [...new Set(tableData.map((item) => item.createdBy))]
    const statuses = tableData && [...new Set(tableData.map((item) => item.status))]
    return (
        <div>

            <Container fluid={true} className='' style={{ paddingTop: '30px', border: '0px solid' }}>
                <div>
                    <H4>
                    User Logs
                    </H4> 
                    <Row className='d-flex justify-content-between mb-3'>
                        <Col xl='6' md='5' xs='12'>
                            {/* <Card className='shadow-none'>
                                <CardBody className='d-flex justify-content-start align-content-center' style={{ padding: '12px 12px 12px 20px' }}>
                                    <img src={search} alt="search icon" style={{ width: '16px', height: '16px', marginRight: '15px', marginTop: '2px' }} />
                                    <input
                                        type="text"
                                        placeholder='Search '
                                        onChange={handleSearch}
                                        style={{ width: '100%', border: '0px', fontSize: '16px', padding: '0px', margin: '0px' }}

                                    />
                                </CardBody>
                            </Card> */}
                            <SearchInput onchange={handleSearch} />
                        </Col>
                        <Col xl='6' md='7 mt-0' xs='12' sm='mt-2'  className='d-flex justify-content-end gap-1 filtersbtnforitlogs'> 
                            <FilterButton filters={creaters} firstOption='Creator Filter' OnChange={handleFilterChange} inputChangeText={'creator'} />
                            <FilterButton filters={statuses} firstOption='Status Filter' OnChange={handleFilterChange} inputChangeText={'status'} />
                     
                    </Col>

                    </Row>
                    <ItDataTable
                        tableColumns={tableColumns}
                        staticData={filteredData ? filteredData : tableData}
                    />
                </div>



            </Container>
        </div>
    );
}

function FilterButton({ filters, firstOption, OnChange, inputChangeText }) {
    return  <Input
        className='form-control rounded-3'
        type='select'
        name='role'
        id='role'
        style={{ width: '145px', fontSize: '15px', height: '47px' }}
        // value={formData.role}
        onChange={(e) => OnChange(e, inputChangeText)}
    >
        <option value=''>{firstOption}</option>
        {filters.map((item, i) => (
            <option key={i} value={item}>{item}</option>
        ))}
    </Input> 



}

export default ItLogs;
