import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Card, Container, Row, Col, CardBody, CardHeader } from 'react-bootstrap'
import { RecentOrderChart } from "../../../Screens/GlobalUser/LiveAnalytics/analytics_data_temp";
import ReactApexChart from "react-apexcharts";
import { ArrowDown, ArrowUp, Camera } from "react-feather";
import '../../../Screens/GlobalUser/AIModelReports/custom.css'
import { AreaOwnerAnlyticsJSON, dummyJSON } from "../../../../Data/staticData/data";
import ProgressBars from "../../../Screens/GlobalUser/LiveAnalytics/components/ProgressBars";
import ApexInteractiveHeatmap from "../../../Screens/GlobalUser/LiveAnalytics/components/HeatMap";
import AlertsTrendChart from "../../../Screens/GlobalUser/LiveAnalytics/components/AlertsTrendChart";
import { getWeek } from "../../../../_helper/helper";
import { H4 } from "../../../../AbstractElements";
import { getCurrentWeekNumber } from "../../../../utils/getCurrentWeekNumber";

const AreaDashboard = () => {
  const [accuracySectionData, setAccuracySectionData] = useState({
    aiAccuracy: undefined,
    connectivity: undefined,
    highSeverityAlerts: undefined,
    maxAlerts: undefined
  })
  const [heatmapData, setHeatmapData] = useState({
    areas: [],
    subAreas: [],
    areaOwner: [],
    data: []
  });
  const [week, setWeek] = useState()
  const [progressData, setProgressData] = useState()
  const [recentOrderChart, setRecentOrderChart] = useState(RecentOrderChart)
  const [CurrentWeek, setCurrentWeek] = useState(undefined)
  const [OwnerName, setOwnerName] = useState('')
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (e) => {
    setTooltipPosition({ top: e.clientY, left: e.clientX });
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleClick = (e) => {
    setTooltipPosition({ top: e.clientY, left: e.clientX });
    setShowTooltip(!showTooltip); // Toggle tooltip on click for mobile devices
  };

  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });


  useEffect(() => {
    //parse JSON data
    const parseJSONData = () => {
      const parsedData = JSON.parse(AreaOwnerAnlyticsJSON);  // Parse the JSON
      const newSeries = parsedData.complianceData.series;  // Extract the series data
      setOwnerName(parsedData?.OwnerName)
      //top right section data
      setAccuracySectionData({
        aiAccuracy: parsedData?.aiAccuracy,
        connectivity: parsedData?.connectivity,
        highSeverityAlerts: parsedData?.highSeverityAlerts,
        maxAlerts: parsedData?.maxAlerts
      });

      //progress bars
      setWeek(parsedData.week);
      setProgressData(parsedData?.progressData);

      //heatmap
      setHeatmapData(parsedData.heatmapData);
      // Set dynamic categories and series for chart
      setChartData({
        categories: parsedData.alertsTrendChart.categories,
        series: parsedData.alertsTrendChart.series,
      });

      // Update the chart state with the new series data
      setRecentOrderChart((recentOrderChart) => ({
        ...recentOrderChart,
        series: newSeries,  // Update series with parsed data
      }));
    };
    parseJSONData();


    // setLoader(true);
    const curr_date = new Date().toLocaleDateString("en-CA");
    // let unsubscribe = fetchAlerts(curr_date);
    const now = new Date();
    const year = now.getFullYear();
    const week = getWeek(now);
    const maxWeek = `${year}-W${String(week).padStart(2, "0")}`;
    setCurrentWeek(maxWeek);
    // return () => unsubscribe;
  }, []);


  const cardClass = `d-flex flex-column  gap-1 gap-sm-2 p-2 p-md-2 justify-content-center`

  return (
    <Fragment>
      <br />
      <Container fluid={true}>
        <Row className="d-flex justify-content-between align-items-center mb-4">
          <Col xs="12" lg="5">
            <H4>Hello {OwnerName}! - AO-6</H4>
            {/* <span className="f-light">Area: AO-6</span> */}
          </Col>
        </Row>
        <Row>
          <Col md={6} xxl={4}>
            <Card style={{ height: "399px" }}>
              <CardHeader>
                <Row>
                  <Col xs="9">
                    <h5>Overall Compliance Score</h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="position-relative p-0">
                <div className="">
                  <ReactApexChart
                    type="radialBar"
                    height={290}
                    options={recentOrderChart.options}
                    series={recentOrderChart.series}
                  />
                </div>
                <div
                  style={{ width: "100%" }}
                  className="d-flex justify-content-center"
                >
                  <span
                    style={{ width: "80%", position: 'absolute', bottom: '15px' }}
                    className="text-center f-light"
                  >
                      Week {getCurrentWeekNumber()} Data
                  </span>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} xxl={4}>
            <ProgressBars week={week} progressData={progressData} areaOwner={true} />
          </Col>
          {/* <Col
           xs='12 mb-1' sm='12 mb-2' md='12' xxl={4}
            style={{ justifyContent: "center", alignItems: "center", margin: '0', padding: '0 12px', }}
          >
            <Col
              className="d-flex gap-2 gap-md-4 gap-xxl-2"
              xs='12'
              style={{ justifyContent: "center", alignItems: "center", width: '100%', }}
            >
             
              <Card className="anlyticsTopCards mb-2" style={{ padding: "20px 10px", width: '50%' }}>
                <CardBody className={`${cardClass}`}>
                  <h6>AI Accuracy</h6>
             
                  <h4 className="mb-0">
                    {accuracySectionData.aiAccuracy?.trend === "down" ? (
                      <ArrowDown color="red" size={20} />
                    ) : (
                      <ArrowUp color="green" size={20} />
                    )}
                    {accuracySectionData.aiAccuracy?.value}%
                  </h4>
                  <span className="f-light">{accuracySectionData.aiAccuracy?.week}</span>
              
                </CardBody>
              </Card>
              <Card className="anlyticsTopCards mb-2" style={{ padding: "20px 10px", width: '50%' }}>
                <CardBody className={`${cardClass}`}>
                  <h6>Connectivity</h6>
                  <h4 className="mb-0">
                   
                    {accuracySectionData.connectivity?.trend === "up" ? (
                      <ArrowUp color="green" size={20} />
                    ) : (
                      <ArrowDown color="red" size={20} />
                    )}
                    {accuracySectionData.connectivity?.value}%
                  </h4>
                 
                  <span className="f-light">{accuracySectionData.connectivity?.status}</span>
                </CardBody>
              </Card>
            </Col>
            <Card className="anlyticsBottomCard" style={{ marginBottom: 0 }}>
              <CardBody className={`w-100 d-flex justify-content-between p-2 p-md-2 `}>
                <div className="d-flex flex-column w-50 gap-2 " style={{ padding: '20px 10px 20px 10px' }}>
                  <h6>High Severity Alerts</h6>
                  <h4 className="mb-0">
                    <ArrowUp color="green" size={20} />
                    {accuracySectionData.highSeverityAlerts?.alerts}
                  </h4>
                  <span>
                    <span className="f-light ellipsis-text">Module: {accuracySectionData.highSeverityAlerts?.module}</span>
                    <span className="f-light d-block ellipsis-text">Camera: {accuracySectionData.highSeverityAlerts?.camera}</span>
                    <span className="f-light d-block ellipsis-text">Sub Area: DRY Store 1, 2</span>
                  </span>
                </div>
                <div className="d-flex flex-column w-50 gap-2 " style={{ padding: '20px 15px' }}>
                  <h6 >Camera Details <span style={{ visibility: 'hidden' }}>Ale</span></h6>
                  <h4 className="mb-0 d-flex align-items-center"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                  >Camera 2</h4>
                  <span>

                    <span className="f-light d-block ellipsis-text">ID: ICF-AO6-D2C2-2-2-10-58</span>
                    <span className="f-light d-block ellipsis-text">IP: 192.168.10.58</span>
                  </span>
                </div>

              </CardBody>
            </Card>
          </Col> */}
          <Col
           xs='12' sm='mb-4' md={12} xxl={4}
            style={{ justifyContent: "center", alignItems: "center", margin: '0', padding: '0 12px' }}
            className="AiAccuracy-section"
          >

            <Col
              className="AiAccuracy-section-cards d-flex gap-sm-4"
              xs='12'
              style={{ justifyContent: "center", alignItems: "center", width: '100%', }}
            >
              {/* {mb-md-4 mb-xxl-2} */}
              <Card className="anlyticsTopCards" style={{ padding: "20px 10px", width: '50%' }}>
                <CardBody className={`${cardClass}`}>
                  <h6>AI Accuracy</h6>
                  {/* <h4 className="mb-0"> <ArrowDown color="red" size={20} />{85}%</h4> */}
                  <h4 className="mb-0">
                    {accuracySectionData.aiAccuracy?.trend === "down" ? (
                      <ArrowDown color="red" size={20} />
                    ) : (
                      <ArrowUp color="green" size={20} />
                    )}
                    {accuracySectionData.aiAccuracy?.value}%
                  </h4>
                  <span className="f-light">Week {getCurrentWeekNumber()} Data</span>
                  {/* <span className="f-light">Week 42 Data</span> */}
                </CardBody>
              </Card>
              <Card className="anlyticsTopCards" style={{ padding: "20px 10px", width: '50%' }}>
                <CardBody className={`${cardClass}`}>
                  <h6>Connectivity</h6>
                  <h4 className="mb-0">
                    {/* <ArrowUp color="green" size={20} />
                  100% */}
                    {accuracySectionData.connectivity?.trend === "up" ? (
                      <ArrowUp color="green" size={20} />
                    ) : (
                      <ArrowDown color="red" size={20} />
                    )}
                    {accuracySectionData.connectivity?.value}%
                  </h4>
                  {/* <span className="f-light">All systems operational</span> */}
                  <span className="f-light">{accuracySectionData.connectivity?.status}</span>
                </CardBody>
              </Card>
            </Col>
            <Card className="anlyticsBottomCard">
              <CardBody className={`w-100 d-flex justify-content-between p-2 p-md-2 `}>
                <div className="d-flex align-self-center flex-column w-50 gap-2" style={{ padding: '20px 10px 20px 10px' }}>
                  <h6>High Severity Alerts</h6>
                  <h4 className="mb-0">
                    <ArrowUp color="green" size={20} />
                    {accuracySectionData.highSeverityAlerts?.alerts}
                  </h4>
                  <span>
                    <span className="f-light ellipsis-text">Module: {accuracySectionData.highSeverityAlerts?.module}</span>
                    <span className="f-light d-block ellipsis-text">Sub Area: DRY Store 1, 2</span>
                  </span>
                </div>
                <div className="d-flex flex-column align-self-center w-50 gap-2" style={{ padding: '20px 15px' }}>
                <h6 >Camera Details <span style={{ visibility: 'hidden' }}>Ale</span></h6>
                  <h4 className="mb-0 d-flex align-items-center"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                  >Camera 2</h4>
                  <span>

                    <span className="f-light d-block ellipsis-text">ID: ICF-AO6-D2C2-2-2-10-58</span>
                    <span className="f-light d-block ellipsis-text">IP: 192.168.10.58</span>
                  </span>
                </div>
              </CardBody>
            </Card>
            
          </Col>

        </Row>
        <Row className=" ">
          <Col xs={12} lg={12} md={12} sm={12} xl={12}>
            <Card className="shadow-sm w-100">
              <Card.Header className="text-black">
                <h5 className="mb-0">Area Intensity Heatmap</h5>
              </Card.Header>
              <Card.Body className="p-0 p-md-3 p-lg-4 w-100">

                <div className="table-responsive w-100">
                  <div className="mb-4 w-100">
                    <ApexInteractiveHeatmap
                      heatmapData={heatmapData}
                      area={true}
                      moduleLength={progressData?.length}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <AlertsTrendChart chartData={chartData} />
          </Col>
        </Row>
      </Container>
    </Fragment >
  );
};

export default AreaDashboard;

























////////////////////////////////////previous code ////////////////////////////////////////
// import React from 'react'
// import Production from '../../GlobalDashboard/AreawiseReports/production/Production'

// const Index = () => {
//   return (
//     <>
//      <Production mainTitle={'Hello Aftab!'} type={'area'}/>
//     </>
//   )
// }

// export default Index
