import React from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { dateChoose } from '../data/staticData';

export default function DateFilter({allData, shouldShowButton,style, selectedOption,selectArea, currentWeek,typeHeadChange,filters,handleDateDrop,handleInputChange, dateShow,monthShow,weeklyShow,customDate}) {
    const multiple = true;
  return (
    <>
          <FormGroup className='mx-2'>
      {!selectArea && <Typeahead
                style={{minWidth:'112px'}}
          id="areas"
          labelKey="name"
          multiple={multiple}
          options={['AO-1', 'AO-2', 'AO-3', 'AO-4', 'AO-5', 'AO-6', 'AO-7', 'AO-8', 'AO-9', 'AO-10', 'AO-11', 'AO-12', 'AO-13', 'AO-14', 'AO-15']}
          selected={filters.areas}
          placeholder="Select Area"
          onChange={(selected) => typeHeadChange('areas', selected)}
        />}
      </FormGroup>
      <FormGroup className='mx-2'>
        <Typeahead
        style={{minWidth:'112px'}}
          id="areas"
          labelKey="name"
          multiple={multiple}
          options={['Shift A', 'Shift B', 'Shift C']}
          selected={filters.shifts}
          placeholder="Select Shift"
          onChange={(selected) => typeHeadChange('shifts', selected)}
        />
      </FormGroup>
      <FormGroup className='mx-2 my-0'>
                <Input
                  className='rounded-3'
                  type="select"
                  name="role"
                  id="role"
                  style={style}
                  value={selectedOption}
                  onChange={handleDateDrop}  
                >
                  {/* <option value="Select Date">
          {selectedOption === 'Select Date' ? 'Select Date' : selectedOption}
        </option> */}
                  {dateChoose.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </Input>
              </FormGroup>
     
              {
                dateShow && (
                  <>
                  <FormGroup className='mx-2'>
                    <Input
                      className="form-control rounded-3"
                      type="date"
                      name="role"
                      id="role"
                      max={new Date().toLocaleDateString("en-CA")}
                      value={filters.date}
                      style={style}
                      onChange={(e)=>handleInputChange(e,'date')}
                    />
                    </FormGroup>
                  </>
                )
              }
              {
                monthShow && (
                  <>
                  <FormGroup className='mx-2'>
                   <Input
                className="form-control rounded-3"
                type="month"
                name="month"
                id="role"
                max={new Date().toISOString().slice(0, 7)}
                value={filters.month}
                style={style}
                onChange={(e)=>handleInputChange(e,'month')}
              />
              </FormGroup>
                  </>
                )
              }
              {
                weeklyShow && (
                  <>
                  <FormGroup className='mx-2'>
                    <Input
                      className="form-control  rounded-3"
                      type="week"
                      name="week"
                      id="week"
                      max={currentWeek}
                      value={filters?.week}
                      style={style}
                    onChange={(e)=>handleInputChange(e,'week')}
                    />
                    </FormGroup>
                  </>
                )
              }
              {
                customDate && (
                  <>
                    <div className=''>
                      <FormGroup className='mx-2'>
                      <Input
                        className=" rounded-3 "
                        
                        type="date"
                        name="start_date"
                        id="start_date"
                        multiple={true}
                        max={new Date().toLocaleDateString("en-CA")}
                        value={filters?.starting}
                        style={style}
                      onChange={(e)=>handleInputChange(e,'starting')}
                      />
                      </FormGroup>
                      <FormGroup className='mx-2'>
                      <Input
                        className="form-control rounded-3 "
                        type="date"
                        name="end_date"
                        id="end_date"
                        placeholder="End Date"
                        // disabled={filters?.start_date?.trim() === ""}
                        // min={filters?.start_date}
                        max={new Date().toLocaleDateString("en-CA")}
                        value={filters?.ending}
                        style={style}
                        onChange={(e)=>handleInputChange(e,'ending')}
                      />
                      </FormGroup>
                      {/* <Button
                        color="success"
                        style={{
                          padding: "0px 10px",
                          height: "38px",
                          // margin: "10px 3px",
                        }}
                      // onClick={() => fetchCustomDurationAlerts()}
                      >
                        Save
                      </Button> */}
                    </div>
                  </>
                )
              }
    
    </>
  )
}
