import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function TotalAlerts({ filters, defaultData, chartsData, lastFilter,setLastFilter,data,setData }) {
 
console.log('this is alerts cahrt')

const categoryAbbreviations = {
    "Emergency Exit": "E. Exit",
    "MMHE": "MMHE",
    "Helmet": "Helmet",
    "Vest": "Vest",
    "Machine Guard": "M. Guard"
};
    // Track shift changes
    useEffect(() => {
        
            setLastFilter('shifts'); // Set 'shifts' as the latest filter
        
    }, [filters?.shifts]);
    useEffect(() => {
       
            setLastFilter('areas'); // Set 'areas' as the latest filterr
    
    }, [ filters?.areas]);

    // Update the data based on the latest filter (shift or week)
    useEffect(() => {
      // Update the data state with new values
    }, [lastFilter, filters]);

    // Create series for the different segments (Low, Medium, Severe)
    // const createSeries = () => {
    //     const blueSeries = [];
    //     const orangeSeries = [];
    //     const redSeries = [];

    //     data.forEach((item) => {
    //         // Blue segment (up to 40)
    //         blueSeries.push(Math.min(item.value, 40));

    //         // Orange segment (41 to 60)
    //         orangeSeries.push(item.value > 40 ? Math.min(item.value, 60) - 40 : 0);

    //         // Red segment (above 60)
    //         redSeries.push(item.value > 60 ? item.value - 60 : 0);
    //     });

    //     return [
    //         { name: 'Low', data: blueSeries, color: '#40a1ff' },
    //         { name: 'Medium', data: orangeSeries, color: '#f9c50a' },
    //         { name: 'Severe', data: redSeries, color: '#ff7340' },
    //     ];
    // };

    // const createSeries = () => {
    //     return [{
    //         name: 'Alerts',
    //         data: data.map(item => item.value),  // Use the values from the `data`
    //         colors: data.map(item => {
    //             if (item.category === 'Helmet' || item.category === 'Emergency Exit' || item.category === 'Machine Guard') {
    //                 return '#ff0000'; // Red for Helmet, Emergency Exit, and Machine Guard
    //             } else if (item.category === 'MMHE') {
    //                 return '#f9c50a'; // Yellow for MMHE
    //             } else if (item.category === 'Vest') {
    //                 return '#40a1ff'; // Blue for Vest
    //             }
    //             // Default color (black) if category doesn't match
    //         }),
    //     }];
    // };

    const createSeries = () => {
        return [
            {
                name: 'High Severity',
                data: data?.map(item => 
                    (item?.category === 'MMHE' || item?.category === 'Emergency Exit') 
                        ? item.value : 0),  // Include value for High Severity categories
                color: '#ef4343',  // Red for High Severity
            },
          
            {
                name: 'Medium Plus Severity',
                data: data?.map(item => 
                    (item?.category === 'Helmet' || item?.category === 'Vest') 
                        ? item?.value : 0),  // Include value for Medium Plus Severity category (Vest)
                color: '#f0ab43',  // Blue for Medium Plus Severity
            },
            {
                name: 'Medium Severity',
                data: data?.map(item => 
                    item?.category === 'Machine Guard' 
                        ? item?.value : 0),  // Include value for Medium Severity category (MMHE)
                color: '#40a1ff',  // Yellow for Medium Severity
            },
        ];
    };
    
    

    const options = {
        chart: {
            type: 'bar',
            stacked: true, // Disable stacked bars
            height:350,
        },
        toolbar: {
            show: false
        },
        dataLabels: {
            enabled: true,  // Enable data labels
           // Position labels at the top of the bars
            style: {
                colors: ['#ffffff'],  // Optionally set the color of the labels
                fontSize: '11px',  // Adjust font size for readability
            },
            formatter: function (val, opts) {
                return `${val}`
            },
            textAnchor: 'middle',
         
        },
        tooltip: {
            enabled: true, // Disable tooltip on hover
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadiusWhenStacked: 'all',
                columnWidth: '65%',
                distributed: false, // Ensures each bar gets its assigned color
                borderRadiusApplication: 'around',
               
                borderRadius: 4,
            },
        
        },
        // colors: data?.map((item) => {
        //     if (item.value > 95) {
        //         return '#32CD32'; // Green for values above 95 (Medium Plus Severity)
        //     } else if (item?.value >= 80 && item?.value <= 95) {
        //         return '#f9c50a'; // Yellow for values between 80 and 95 (Medium Severity)
        //     } else if (item?.value < 80) {
        //         return '#ff0000'; // Red for values below 80 (High Severity)
        //     }
        // }),
        
        // Manually setting the colors based on the categories
        xaxis: {
            categories: data?.map((item) => categoryAbbreviations[item?.category] || item?.category), // Category labels for the x-axis
            labels: {
                style: {
                    fontSize: '12px', // Set a default font size
                },
                rotate: 0, // Rotate the labels to fit in limited space
                rotateAlways: false,
                // rotate: 0, // Rotate the labels to fit in limited space
                hideOverlappingLabels: false,
                trim: true, // Trim labels if they are too long
            },
        },
        yaxis: {
            title: {
                text: 'Alerts',
                // offsetY: -150, // Position title at the top
              // Keep the title horizontal
                style: {
                   lineHeight: '2.5',
                    fontSize: '13px',
                    fontWeight: '100',
                    fontFamily:'sans-serif',
                },
            },
            labels: {
                style: {
                    fontSize: '12px', // Set a default font size
                },
               
            },
          
        },
        title: {
            text: 'Summary of Alerts',
            align: 'center',
            style: {
                fontSize: '14px',
                fontWeight: '500',
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: true,
            position: 'bottom',  // Position legend at the bottom
            horizontalAlign: 'center',
            labels: {
                useSeriesColors: false,  // Use the colors defined in the series
            },
            markers: {
                width: 12,
                height: 12,
                radius: 12, // Circular markers for the legend
            },
            formatter: function(val, opts) {
                if (opts?.seriesIndex === 0) return 'High Severity'; // Red
                if (opts?.seriesIndex === 1) return 'Medium Plus Severity'; // Yellow
                if (opts?.seriesIndex === 2) return 'Medium Severity'; // Blue
            },
        },
        responsive: [
            {
                breakpoint: 1367,
                options: {
                    chart: {
                        width: '100%',
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '12px', // Adjust font size for smaller screens
                            },
                            rotate: -45, // Rotate labels for better fit
                        },
                    },
                    
                }
            },
            {
                breakpoint: 992,
                options: {
                    chart: {
                        height:400,
                    },
                   
                }
            },
         
                  {
                breakpoint: 697,
                options: {
                    chart: {
                        width: '100%',
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '12px', // Adjust font size for smaller screens
                            },
                            rotate: -45, // Rotate labels for better fit
                        },
                    },
                }
            },
            {
                breakpoint: 620,
                options: {
                    chart: {
                        width: '100%',
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '10px', // Adjust font size for smaller screens
                            },
                            rotate: -45, // Rotate labels for better fit
                        },
                    },
                    dataLabels: {
                       
                        style: {
                            fontSize: '9px',  // Adjust font size for readability
                        },
                       
                     
                    },
                }
            }
        ]
    };
    
    
    

    return (
        <>
            <ReactApexChart
                options={options}
                series={createSeries()} // Series based on updated data
                type="bar"
                height={options?.chart?.height}
            />
        </>
    );
}
