// import React, { useState, useEffect } from "react";
// import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
// import Loader1 from "../../../../CommonElements/Spinner/loader";
// import CustomPagination from "./Components/pagination/pagination";
// import CameraImage from "../.../../../../../assets//pictures/default_image.jpg";
// import CommonFIlterButton from "../../../Common/commonFilterButton/CommonFIlterButton";
// import { CamersStatus } from "../../../../Data/staticData/data";
// import SingleImage from "../../../../Gallery/zoomin/SingleImage";
// import KPICard from "./Components/KPICards/kpi_card";
// import { Typeahead } from "react-bootstrap-typeahead";
// import "react-bootstrap-typeahead/css/Typeahead.css";
// import AddCameraModel from "./Components/add_camera_model";
// import CameraService from "../../../../api/cameraService";
// import ErrorHeading from "../../../Common/Errors/errorHeading";
// import { ChevronDown, Eye, MoreVertical } from "react-feather";
// import { errorToast, infoToast, OptionToggle, showConfirmationAlert, successToast, truncateName } from "../../../../_helper/helper";
// import moment from "moment";
// import ImageZoom from "../../../Dashboards/AreaDashbaord/reports/Components/LiveAlertsCards/ImageZoom";
// import "./cameras.css";
// import { Navigate, useNavigate } from "react-router";
// import { Link } from "react-router-dom";
// import { Button } from "react-bootstrap";
// import CustomPaginationn from "./Components/pagination/Custom_Pagination";import { replace } from "feather-icons";

// const AllCamerasScreen = ({ area }) => {
//   const navigate = useNavigate();
//   const [allCameras, setAllCameras] = useState([]);
//   const [filterCameras, setFilterCameras] = useState([]);
//   const [showTable, setShowTable] = useState(false);
//   const [showError, setShowError] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [modalData, setModalData] = useState({});
//   const [showModal, setShowModal] = useState(false);
//   const [imageData, setImageData] = useState({});
//   const [expandedIndexes, setExpandedIndexes] = useState([]);
//   const [expandedWebIndexes, setWebExpandedIndexes] = useState([]);

//   const [filters, setFilters] = useState({
//     area: [],
//     status: "",
//     sub_area: [],
//   });
//   const [dropdownOptions, setDropdownOptions] = useState({
//     area_list: [],
//     module_options: [],
//     sub_area_list: [],
//     camera_status: [
//       { id: 1, title: "Active" },
//       { id: 2, title: "InActive" },
//     ],
//   });

//   useEffect(() => {
//     setLoading(true);
//     CameraService.getCameraDropdowns()
//       .then((res) => {
//         const response = res?.data?.data;
//         setDropdownOptions({
//           ...dropdownOptions,
//           module_options: response?.modules || [],
//           area_list: response?.area_list?.sort((a, b) => {
//             // Extract the number part from the area name
//             const numA = parseInt(a.area.match(/\d+/)[0]);
//             const numB = parseInt(b.area.match(/\d+/)[0]);

//             return numA - numB;
//           }),
//         });
//       })
//       .catch((e) => {});
//     fetchAllCameras();
//   }, []);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       fetchAllCameras();
//     }, 120000); // 2 minutes interval
//     return () => clearInterval(interval);
//   });

//   const fetchAllCameras = () => {
//     CameraService.getAllLiveCameras()
//       .then((res) => {
//         const response = res?.data?.data;
//         let updated;
//         if (area) {
//           updated = response
//             ?.reverse()
//             .filter((i) => i?.area_owner.toLowerCase() === "meraj")
//             .map((item, index) => ({
//               ...item,
//               gate: index + 1,
//               modules: item?.modules?.filter(m => m.module_id !== null)
//             }));
//         } else {
//           updated = response?.reverse().map((item, index) => ({
//             ...item,
//             gate: index + 1,
//             modules:  item?.modules?.filter(m => m.module_id !== null)
//           }));
//         }

//         setAllCameras(updated);
//         setFilterCameras(updated);

//         if (updated?.length > 0) {
//           setShowTable(true);
//           setShowError(false);
//         } else {
//           setShowTable(false);
//           setShowError(true);
//         }
//         setLoading(false);
//       })
//       .catch((e) => {
//         setLoading(false);
//         setShowTable(false);
//         setShowError(true);
//       });
//   };

//   const toggleCameraID = (index) => {
//     if (expandedIndexes.includes(index)) {
//       setExpandedIndexes(expandedIndexes.filter((i) => i !== index)); // Collapse
//     } else {
//       setExpandedIndexes([...expandedIndexes, index]); // Expand
//     }
//   };

//   const toggleSubArea = (index) => {
//     if (expandedWebIndexes.includes(index)) {
//       setWebExpandedIndexes(expandedWebIndexes.filter((i) => i !== index)); // Collapse
//     } else {
//       setWebExpandedIndexes([...expandedWebIndexes, index]); // Expand
//     }
//   };

//   const RenderText = ({ title, value, gate }) => {
//     return (
//       <div className="camera_details ellipsis-text">
//         <span className="f-w-500">{title}: </span>
//         {title === "Modules" || title === "Module" ? (
//           value?.map((item, index) => {
//             return (
//               <span key={index}>
//                 {" "}
//                 {item?.module_name} {index < value.length - 1 && " | "}{" "}
//               </span>
//             );
//           })
//         ) : (
//           <span>{value}</span>
//         )}
//       </div>
//     );
//   };

//   const RenderCameraID = ({ item, index }) => {
//     const isExpanded = expandedIndexes.includes(index);
//     const truncatedCameraID = item?.camera_id
//       ?.split("-")
//       ?.slice(0, 3)
//       ?.join("-");

//     return (
//       <div className="camera_details ellipsis-text">
//         <span className="f-w-500">Camera ID: </span>
//         <span className="prospect-address">{item?.camera_id}</span>

//         {/* <OptionToggle
//           onClick={() => toggleCameraID(index)}
//           isExpanded={isExpanded}
//         /> */}
//       </div>
//     );
//   };
//   const RenderSubArea = ({ item, index }) => {
//     const isExpanded = expandedWebIndexes.includes(index);
//     const truncatedCameraID = truncateName(item?.sub_area, 10);

//     return (
//       <div className="camera_details">
//         <span className="f-w-500">Sub Area: </span>
//         <span className="prospect-address">
//           {item?.sub_area}
//           {/* {isExpanded ? item?.sub_area : truncatedCameraID} */}
//         </span>

//         {/* <OptionToggle
//           onClick={() => toggleSubArea(index)}
//           isExpanded={isExpanded}
//         /> */}
//       </div>
//     );
//   };

//   const MenuIconButton = ({ handleEdit, darkColor, handleDelete }) => {
//     return (
//       <span className="pull-right onhover-dropdown  p-0 text-right">
//         <MoreVertical
//           className={`font-${darkColor ? "dark" : "primary"}`}
//           size={20}
//         />
//         <div
//           className="onhover-show-div"
//           style={{
//             position: "absolute",
//             top: 28,
//             left: -50,
//             width: "90px",
//           }}
//         >
//           <ul
//             style={{
//               padding: "5px 10px",
//               textAlign: "left",
//               width: "100px",
//             }}
//           >
//             <li
//               style={{ margin: "5px 5px ", cursor: "pointer" }}
//               onClick={handleEdit}
//             >
//               <p className="menu-title"> Edit</p>
//             </li>

//             <li
//               style={{
//                 margin: "5px 5px ",
//                 cursor: "pointer",
//               }}
//               onClick={handleDelete}
//             >
//               <p className="menu-title">Delete</p>
//             </li>
//           </ul>
//         </div>
//       </span>
//     );
//   };

//   const handleRowEditClick = (camera_id) => {
//     // const queryString = new URLSearchParams({
//     //   models: JSON.stringify(dropdownOptions?.module_options), // Encode models
//     // }).toString();


//     navigate(
//       `${process.env.PUBLIC_URL}/update_camera/${JSON.parse(
//         localStorage.getItem("role")
//       )}`, {state: {camera_id: camera_id,
//         models:dropdownOptions?.module_options
//       }}
//       , 
//     );
//   };
//   const handleDeleteClick = (camera_id) => {
//     showConfirmationAlert("Are you sure you want to delete this Camera?").then((result) => {
//       if (result.value) {
//         CameraService.deleteCamera(camera_id)
//           .then((resp) => {
//             if (resp?.data?.success === true) {
//               const filtereddata = filterCameras?.filter((r) => r.camera_id !== camera_id);
//               setAllCameras(filtereddata);
//               setFilterCameras(filtereddata);
//               if (filtereddata.length === 0) {
//                 setShowTable(false);
//               }

//               successToast(resp?.data?.message);
            
//             } else {
//               infoToast(resp?.data?.message);
//               setShowTable(true);
//             }
//           })
//           .catch((err) => {
//             setShowTable(false);
//             errorToast(err?.response?.data?.message);
//           });
//       }
//     });
//   };

//   const Items = (items) => {
//     return (
//       <Row>
//         {items &&
//           items?.map((item, index) => {
//             return (
//               <Col
//                 key={index}
//                 className="custom-col-card col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6"
//               >
//                 <Card
//                   className={
//                     index % 2 === 0
//                       ? "camera_item_card_odd"
//                       : "camera_item_card_even"
//                   }
//                   key={index + 1}
//                   style={{
//                     marginBottom: 5,
//                     backgroundColor: item?.active ? "white" : "#e2e2e2",
//                   }}
//                 >
//                   <CardBody className="camera_card_body">
//                     <Row
//                       style={{
//                         alignItems: "center",
//                       }}
//                     >
//                       <Media>
//                         <div className="gateNumber mb-2">
//                           Camera #{item?.gate}
//                         </div>
//                         <Media body className="action-options pull-right">
//                           {JSON.parse(localStorage.getItem("role")) ===
//                             "it-officer" && (
//                             <MenuIconButton
//                               handleEdit={() => handleRowEditClick(item?.camera_id)}
//                               handleDelete={() => handleDeleteClick(item?.camera_id)}
//                             />
//                           )}
//                           <Eye 
//                             size={18}
//                             className="view_icon font-primary"
//                             onClick={() => handleRowClick(item)}
//                           />
//                         </Media>
//                       </Media>
//                       <Col className="custom-col-9 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                       
//                         <RenderCameraID item={item} index={index} />

//                         <RenderText
//                           title={"Camera Name"}
//                           value={item?.camera_name}
//                           gate={item}
//                         />

//                         {!area && (
//                           <>
//                             <RenderText
//                               title={"Area"}
//                               value={item?.area}
//                               gate={item}
//                             />
//                             <RenderText
//                               title={"Onwer"}
//                               value={item?.area_owner}
//                               gate={item}
//                             />
//                           </>
//                         )}
//                         <RenderSubArea item={item} index={index} />

//                         <RenderText
//                           title={
//                             item?.modules?.length > 1 ? "Modules" : "Module"
//                           }
//                           value={item?.modules}
//                           gate={item}
//                         />
//                         <RenderText
//                           title={"Last Active"}
//                           value={item?.last_active}
//                           gate={item}
//                         />

//                         <div className="camera_details">
//                           <span className="f-w-500">Status: </span>
//                           <span
//                             className={`badge ${
//                               item?.active ? "bg-success" : "bg-danger"
//                             }`}
//                             style={{
//                               fontWeight: 500,
//                               fontSize: 13,
//                             }}
//                           >
//                             {" "}
//                             {item?.active ? "Active" : "Inactive"}
//                           </span>
//                         </div>
//                       </Col>
//                       <Col className="custom_camera_card col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-end">
//                         <img
//                           src={item?.image_url ? item?.image_url : CameraImage}
//                           alt="Camera"
//                           className="camera_image"
//                           style={{
//                             color: "gray",
//                           }}
//                           onClick={() => handleRowClick(item)}
//                         />
//                       </Col>
//                     </Row>
//                   </CardBody>
//                 </Card>
//               </Col>
//             );
//           })}
//       </Row>
//     );
//   };

//   const handleFilterChange = (e, field) => {
//     setFilters({
//       ...filters,
//       [field]: e.target.value,
//     });
//   };
//   useEffect(() => {
//     applyFilters(filters, filterCameras);
//   }, [filters, filterCameras]);

//   const applyFilters = (currentFilters, dataList) => {
//     let filtered = dataList;

//     // Apply area filter
//     if (currentFilters.area) {
//       const selected_areas = currentFilters?.area?.map((i) => i.area);
//       filtered = filtered.filter(
//         (item) =>
//           selected_areas.length === 0 || selected_areas?.includes(item.area)
//       );
//     }
//     // Apply sub area filter
//     if (currentFilters.sub_area) {
//       const selected_sub_areas = currentFilters?.sub_area?.map((i) =>
//         i.name?.trim()?.toLowerCase()
//       );
//       filtered = filtered.filter((item) => {
//         return (
//           selected_sub_areas.length === 0 ||
//           selected_sub_areas?.includes(item.sub_area?.trim()?.toLowerCase())
//         );
//       });
//     }

//     // Apply module filter
//     if (currentFilters.status) {
//       const val = currentFilters.status === "Active" ? true : false;
//       filtered = filtered.filter((item) => item.active === val);
//     }

//     const updated_data = filtered?.map((i, index) => ({
//       ...i,
//       sr: index + 1,
//     }));
//     setAllCameras(updated_data);
//   };

//   const style = {
//     width: "160px",
//     height: "38px",
//     fontSize: 13,
//     margin: "5px 3px",
//     display: "inline-block",
//   };

//   const handleRowClick = (item) => {
//     setImageData({
//       photo: item.image_url,
//       cameraName: item.camera_name,
//       date: item?.last_active
//         ? moment(item?.last_active).format("DD MMM, YYYY")
//         : "",
//       time: item?.last_active
//         ? moment(item?.last_active).format("hh:mm A")
//         : "",
//     });
//     setModalData(item);
//     setShowModal(!showModal);
//   };

//   const handleAreaChange = (selected) => {
//     const subareas = selected?.reduce((acc, currentArea) => {
//       return [...acc, ...currentArea?.sub_area];
//     }, []);
//     setFilters({ ...filters, area: selected, sub_area: [] });
//     setDropdownOptions({ ...dropdownOptions, sub_area_list: subareas });
//   };

//   const handleSubAreaChange = (selected) => {
//     setFilters({ ...filters, sub_area: selected });
//   };

//   const handleSaveCamera = (record) => {
//     fetchAllCameras();
//   };

  
//   const handleNavigation = () => {
//     const queryString = new URLSearchParams({
//       areas_list: JSON.stringify(dropdownOptions?.area_list), // Encode areas_list
//       models: JSON.stringify(dropdownOptions?.module_options), // Encode models
//       handleSave: handleSaveCamera, // Encode the function as a string (optional)
//     }).toString();

//     navigate(
//       `${process.env.PUBLIC_URL}/addcameras/${JSON.parse(
//         localStorage.getItem("role")
//       )}?${queryString}`
//     );
//   };

//   return (
//     <>
//       <br />
//       <Container fluid>
//         <Row style={{ marginBottom: 0, alignItems: "flex-start" }}>
//           <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 col-xxl-2">
//             <h5 className="d-inline-block">Cameras</h5>
//           </Col>
//           <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10">
//             <div className="filter-row position-relative">
//              {!area && <div style={{ display: "inline-block", position: "relative" }}>
//                 <ChevronDown
//                   style={{
//                     position: "absolute",
//                     top: "15px",
//                     right: "11px",
//                     zIndex: "1000",
//                   }}
//                   size={18}
//                   color="#383e44"
//                 />
//                 <Typeahead
//                   id="area-typeahead"
//                   name="area"
//                   className="custom-typeahead rounded-3"
//                   labelKey="area"
//                   multiple
//                   options={dropdownOptions?.area_list}
//                   selected={filters?.area}
//                   onChange={handleAreaChange}
//                   placeholder="Select Areas"
//                   style={{
//                     display: "inline-block",
//                     textTransform: "capitalize",
//                     maxWidth: "290px",
//                     minWidth: "200px",
//                   }}
//                 />
//               </div>}

//               <div style={{ display: "inline-block", position: "relative" }}>
//                 <ChevronDown
//                   style={{
//                     position: "absolute",
//                     top: "15px",
//                     right: "11px",
//                     zIndex: "1000",
//                   }}
//                   size={18}
//                   color="#383e44"
//                 />

//                 <Typeahead
//                   id="sub-area-typeahead"
//                   name="sub-area"
//                   className="custom-typeahead rounded-3"
//                   labelKey="name"
//                   multiple
//                   options={dropdownOptions?.sub_area_list}
//                   selected={filters?.sub_area}
//                   onChange={handleSubAreaChange}
//                   placeholder="Select Sub Areas"
//                   style={{
//                     display: "inline-block",
//                     textTransform: "capitalize",
//                     maxWidth: "290px",
//                     minWidth: "200px",
//                     fontSize: "11px",
//                   }}
//                 />
//               </div>

//               <CommonFIlterButton
//                 data={CamersStatus}
//                 handleInputChange={handleFilterChange}
//                 style={style}
//                 selectedItem={filters?.status}
//                 firstOption={"Select Status"}
//                 inputChangeOption={"status"}
//                 className={""}
//               /> 
//               {JSON.parse(localStorage.getItem("role")) === "it-officer" && (
//                 <Button
//                   className="rounded-3 d-inline-block"
//                   color="primary"
//                   style={{
//                     height: "38px",
//                     fontSize: 14,
//                     margin: "5px 3px",
//                     padding: "0px 10px",
//                     width: "120px",
//                   }}
//                   onClick={handleNavigation}
//                 >
//                   Add Cameras
//                 </Button>
//               )}
//             </div>
//           </Col>
//         </Row>

//         {loading ? (
//           <center
//             style={{ textAlign: "center", marginTop: "15%", marginBottom: 40 }}
//           >
//             <Loader1 />
//           </center>
//         ) : showTable ? (
//           <>
//             <Row style={{ marginTop: 5 }}>
//               <Col
//                 style={{ height: "137px" }}
//                 className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
//               >
//                 <KPICard
//                   data={{
//                     title: "Total Cameras",
//                     gros: 50,
//                     total: allCameras?.length || 0,
//                     showPercentage: false,
//                   }}
//                   mainClass="kpi_card_total"
//                 />
//               </Col>
//               <Col
//                 style={{ height: "137px" }}
//                 className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
//               >
//                 <KPICard
//                   data={{
//                     title: "Live Cameras",
//                     gros:
//                       allCameras?.length > 0
//                         ? Math.round(
//                             (allCameras?.filter((i) => i?.active === true)
//                               ?.length /
//                               allCameras?.length) *
//                               100
//                           ).toFixed(0)
//                         : 0,
//                     total:
//                       allCameras?.filter((i) => i?.active === true)?.length ||
//                       0,
//                     showPercentage: true,
//                   }}
//                   mainClass="kpi_card_live"
//                 />
//               </Col>
//             </Row>
//             <Row style={{ marginBottom: 20, marginTop: 5 }}>
//               <Col>
//                 <CustomPagination
//                   data={allCameras}
//                   itemsPerPage={10}
//                   renderItems={Items}
//                 />
//               </Col>

              
//               {/* custom pagination according to 20 objects */}
//               {/* <CustomPaginationn/> */}

//             </Row>
//           </>
//         ) : (
//           showError && <ErrorHeading />
//         )}
//         {showModal && (
//           <ImageZoom
//             photo={modalData?.image_url ? modalData?.image_url : CameraImage}
//             setIsOpen={setShowModal}
//             setShowModal={setShowModal}
//             imageData={imageData}
//           />
//         )}
//       </Container>
//     </>
//   );
// };

// export default AllCamerasScreen;

import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Media } from "reactstrap";
import Loader1 from "../../../../CommonElements/Spinner/loader";
import Loader3 from "../../../../CommonElements/Spinner/loader3";
import CustomPagination from "./Components/pagination/pagination";
import CameraImage from "../.../../../../../assets//pictures/default_image.jpg";
import CommonFIlterButton from "../../../Common/commonFilterButton/CommonFIlterButton";
import { CamersStatus } from "../../../../Data/staticData/data";
import SingleImage from "../../../../Gallery/zoomin/SingleImage";
import KPICard from "./Components/KPICards/kpi_card";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import AddCameraModel from "./Components/add_camera_model";
import CameraService from "../../../../api/cameraService";
import ErrorHeading from "../../../Common/Errors/errorHeading";
import { ChevronDown, Eye, MoreVertical } from "react-feather";
import { errorToast, infoToast, OptionToggle, showConfirmationAlert, successToast, truncateName } from "../../../../_helper/helper";
import moment from "moment";
import ImageZoom from "../../../Dashboards/AreaDashbaord/reports/Components/LiveAlertsCards/ImageZoom";
import "./cameras.css";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import CustomPaginationn from "./Components/pagination/Custom_Pagination"; import { replace } from "feather-icons";
import ConfirmationModals from '../../../Dashboards/ItDashboard/It Officer/Components/ConifrmationModal/Confirmation_Modal';

const AllCamerasScreen = ({ area }) => {
  const navigate = useNavigate();
  const [allCameras, setAllCameras] = useState([]);
  const [filterCameras, setFilterCameras] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [imageData, setImageData] = useState({});
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const [expandedWebIndexes, setWebExpandedIndexes] = useState([]);
  // State to manage loading status for each image by its unique ID
  const [imageLoadingStatus, setImageLoadingStatus] = useState({});
  const [cameraStatusModal, setcameraStatusModal] = useState(false);
  const handleCameraStatus=()=>setcameraStatusModal(!cameraStatusModal)
const [modalDataForToggle, setmodalDataForToggle] = useState({
  name:'',
  status:false,
  id:''
})
  // Handler for image load completion
  // const handleImageLoad = (id) => {

  //   setImageLoadingStatus((prevState) => ({
  //     ...prevState,
  //     [id]: false
  //   }));
  // };
  const handleImageLoad = (id) => {
    setTimeout(() => {
      setImageLoadingStatus((prevState) => ({
        ...prevState,
        [id]: false
      }));
    }, 400)
  };
  console.log("isImageLoading", imageLoadingStatus)


  const [filters, setFilters] = useState({
    area: [],
    status: "",
    sub_area: [],
  });
  const [dropdownOptions, setDropdownOptions] = useState({
    area_list: [],
    module_options: [],
    sub_area_list: [],
    camera_status: [
      { id: 1, title: "Active" },
      { id: 2, title: "InActive" },
    ],
  });

  useEffect(() => {
    setLoading(true);
    CameraService.getCameraDropdowns()
      .then((res) => {
        const response = res?.data?.data;
        setDropdownOptions({
          ...dropdownOptions,
          module_options: response?.modules || [],
          area_list: response?.area_list?.sort((a, b) => {
            // Extract the number part from the area name
            const numA = parseInt(a.area.match(/\d+/)[0]);
            const numB = parseInt(b.area.match(/\d+/)[0]);

            return numA - numB;
          }),
        });
      })
      .catch((e) => { });
    fetchAllCameras();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAllCameras();
    }, 120000); // 2 minutes interval
    return () => clearInterval(interval);
  });

  const fetchAllCameras = () => {
    CameraService.getAllLiveCameras()
      .then((res) => {
        const response = res?.data?.data;
        let updated;
        if (area) {
          updated = response
            ?.reverse()
            .filter((i) => i?.area_owner.toLowerCase() === "meraj")
            .map((item, index) => ({
              ...item,
              gate: index + 1,
              modules: item?.modules?.filter(m => m.module_id !== null)
            }));
        } else {
          updated = response?.reverse().map((item, index) => ({
            ...item,
            gate: index + 1,
            modules: item?.modules?.filter(m => m.module_id !== null)
          }));
        }

        setAllCameras(updated);
        setFilterCameras(updated);

        if (updated?.length > 0) {
          setShowTable(true);
          setShowError(false);
        } else {
          setShowTable(false);
          setShowError(true);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setShowTable(false);
        setShowError(true);
      });
  };

  const toggleCameraID = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index)); // Collapse
    } else {
      setExpandedIndexes([...expandedIndexes, index]); // Expand
    }
  };

  const toggleSubArea = (index) => {
    if (expandedWebIndexes.includes(index)) {
      setWebExpandedIndexes(expandedWebIndexes.filter((i) => i !== index)); // Collapse
    } else {
      setWebExpandedIndexes([...expandedWebIndexes, index]); // Expand
    }
  };

  const RenderText = ({ title, value, gate }) => {
    return (
      <div className="camera_details ellipsis-text">
        <span className="f-w-500">{title}: </span>
        {title === "Modules" || title === "Module" ? (
          value?.map((item, index) => {
            return (
              <span key={index}>
                {" "}
                {item?.module_name} {index < value.length - 1 && " | "}{" "}
              </span>
            );
          })
        ) : (
          <span>{value}</span>
        )}
      </div>
    );
  };

  const RenderCameraID = ({ item, index }) => {
    const isExpanded = expandedIndexes.includes(index);
    const truncatedCameraID = item?.camera_id
      ?.split("-")
      ?.slice(0, 3)
      ?.join("-");

    return (
      <div className="camera_details ellipsis-text">
        <span className="f-w-500">Camera ID: </span>
        <span className="prospect-address">{item?.camera_id}</span>

        {/* <OptionToggle
          onClick={() => toggleCameraID(index)}
          isExpanded={isExpanded}
        /> */}
      </div>
    );
  };
  const RenderSubArea = ({ item, index }) => {
    const isExpanded = expandedWebIndexes.includes(index);
    const truncatedCameraID = truncateName(item?.sub_area, 10);

    return (
      <div className="camera_details">
        <span className="f-w-500">Sub Area: </span>
        <span className="prospect-address">
          {item?.sub_area}
          {/* {isExpanded ? item?.sub_area : truncatedCameraID} */}
        </span>

        {/* <OptionToggle
          onClick={() => toggleSubArea(index)}
          isExpanded={isExpanded}
        /> */}
      </div>
    );
  };

  const MenuIconButton = ({ handleEdit, darkColor, handleDelete,handlestatus }) => {
    return (
      <span className="pull-right onhover-dropdown  p-0 text-right">
       <MoreVertical
        className={`font-${darkColor ? "dark" : "primary"}`}
        size={20}
      />

        <div
          className="onhover-show-div"
          style={{
            position: "absolute",
            top: 28,
            left: -70,
            width: "150px",
          }}
        >
          <ul
            style={{
              padding: "5px 10px",
              textAlign: "left",
              width: "140px",
            }}
          >
            <li
              style={{ margin: "5px 5px ", cursor: "pointer" }}
              onClick={handleEdit}
            >
              <p className="menu-title"> Edit</p>
            </li>

            <li
              style={{
                margin: "5px 5px ",
                cursor: "pointer",
              }}
              onClick={handleDelete}
            >
              <p className="menu-title">Delete</p>
            </li>
            <li
              style={{
                margin: "5px 5px ",
                cursor: "pointer",
              }}
              onClick={handlestatus}
            >
              <p className="menu-title">Change status</p>
            </li>
          </ul>
        </div>
      </span>
    );
  };

  const handleRowEditClick = (camera_id) => {
    navigate(
      `${process.env.PUBLIC_URL}/update_camera/${JSON.parse(
        localStorage.getItem("role")
      )}`, {
      state: {
        camera_id: camera_id,
        models: dropdownOptions?.module_options
      }
    }
      ,
    );
  };
  const handleDeleteClick = (camera_id) => {
    showConfirmationAlert("Are you sure you want to delete this Camera?").then((result) => {
      if (result.value) {
        CameraService.deleteCamera(camera_id)
          .then((resp) => {
            if (resp?.data?.success === true) {
              const filtereddata = filterCameras?.filter((r) => r.camera_id !== camera_id);
              setAllCameras(filtereddata);
              setFilterCameras(filtereddata);
              if (filtereddata.length === 0) {
                setShowTable(false);
              }

              successToast(resp?.data?.message);

            } else {
              infoToast(resp?.data?.message);
              setShowTable(true);
            }
          })
          .catch((err) => {
            setShowTable(false);
            errorToast(err?.response?.data?.message);
          });
      }
    });
  };
  const handleCameraToggle = (data) =>{ 
    console.log(data,'data')
    if(data){
    setmodalDataForToggle({
      name:data.area_owner,
      id:data.camera_id, 
      status:data.active
    })
    handleCameraStatus();
  }
  }


  const Items = (items) => {
    return (
      <Row>
        {items &&
          items?.map((item, index) => {
            return (
              <Col
                key={index}
                className="custom-col-card col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-6"
              >
                <Card
                  className={
                    index % 2 === 0
                      ? "camera_item_card_odd"
                      : "camera_item_card_even"
                  }
                  key={index + 1}
                  style={{
                    marginBottom: 5,
                    backgroundColor: item?.active ? "white" : "#e2e2e2",
                  }}
                >
                  <CardBody className="camera_card_body">
                    <Row
                      style={{
                        alignItems: "center",
                      }}
                    >
                      <Media>
                        <div className="gateNumber mb-2">
                          Camera #{item?.gate}
                        </div>
                        <Media body className="action-options pull-right">
                          {JSON.parse(localStorage.getItem("role")) ===
                            "it-officer" && (
                              
                            <MenuIconButton
                              handleEdit={() => handleRowEditClick(item?.camera_id)}
                              handleDelete={() => handleDeleteClick(item?.camera_id)}
                              handlestatus={()=> handleCameraToggle(item?item:null)}
                            />
                          )}
                          <Eye
                            size={18}
                            className="view_icon font-primary"
                            onClick={() => handleRowClick(item)} 
                          />
                        </Media>
                      </Media>
                      <Col className="custom-col-9 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">

                        <RenderCameraID item={item} index={index} />

                        <RenderText
                          title={"Camera Name"}
                          value={item?.camera_name}
                          gate={item}
                        />

                        {!area && (
                          <>
                            <RenderText
                              title={"Area"}
                              value={item?.area}
                              gate={item}
                            />
                            <RenderText
                              title={"Onwer"}
                              value={item?.area_owner}
                              gate={item}
                            />
                          </>
                        )}
                        <RenderSubArea item={item} index={index} />

                        <RenderText
                          title={
                            item?.modules?.length > 1 ? "Modules" : "Module"
                          }
                          value={item?.modules}
                          gate={item}
                        />
                        {item?.last_active && <RenderText
                          title={"Last Active"}
                          value={item?.last_active}
                          gate={item}
                        />}

                        <div className="camera_details">
                          <span className="f-w-500">Status: </span>
                          <span
                            className={`badge ${item?.active ? "bg-success" : "bg-danger"
                              }`}
                            style={{
                              fontWeight: 500,
                              fontSize: 13,
                            }}
                          >
                            {" "}
                            {item?.active ? "Active" : !item?.last_active ? 'Nill' : "Inactive"}
                          </span>
                        </div>
                      </Col>
                      <Col className="custom_camera_card col-3 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-end">
                        {/* {imageLoadingStatus[index] !== false && (
                          <div className="image-loader">
                            <span>Loading image...</span>
                          </div>
                        )}

                        <img
                          src={item?.image_url ? item?.image_url : CameraImage}
                          alt="Camera"
                          className="camera_image"
                          style={{
                            color: "gray",
                          }}
                          onClick={() => handleRowClick(item)}
                          onLoad={() => handleImageLoad(index)}
                        /> */}
                        {imageLoadingStatus[index] !== false && (
                          <div className="" style={{display: 'flex', justifyContent: 'center', alignItems:'center', width:'100%', height: '100%', }}>
                            <span className="loader-for-image"><Loader3 /></span>
                          </div>
                        )}

                        <img
                          src={item?.image_url ? item?.image_url : CameraImage}
                          alt="Camera"
                          className={`camera_image ${imageLoadingStatus[index] === false ? 'visible' : ''}`}
                          style={{
                            color: "gray",
                          }}
                          onLoad={() => handleImageLoad(index)}
                          onClick={() => handleRowClick(item)}
                        />


                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
         
      </Row>
    );
  };

  const handleFilterChange = (e, field) => {
    setFilters({
      ...filters,
      [field]: e.target.value,
    });
  };
  useEffect(() => {
    applyFilters(filters, filterCameras);
  }, [filters, filterCameras]);

  const applyFilters = (currentFilters, dataList) => {
    let filtered = dataList;

    // Apply area filter
    if (currentFilters.area) {
      const selected_areas = currentFilters?.area?.map((i) => i.area);
      filtered = filtered.filter(
        (item) =>
          selected_areas.length === 0 || selected_areas?.includes(item.area)
      );
    }
    // Apply sub area filter
    if (currentFilters.sub_area) {
      const selected_sub_areas = currentFilters?.sub_area?.map((i) =>
        i.name?.trim()?.toLowerCase()
      );
      filtered = filtered.filter((item) => {
        return (
          selected_sub_areas.length === 0 ||
          selected_sub_areas?.includes(item.sub_area?.trim()?.toLowerCase())
        );
      });
    }

    // Apply module filter
    if (currentFilters.status) {
      const val = currentFilters.status === "Active" ? true : false;
      filtered = filtered.filter((item) => item.active === val);
    }

    const updated_data = filtered?.map((i, index) => ({
      ...i,
      sr: index + 1,
    }));
    setAllCameras(updated_data);
  };

  const style = {
    width: "160px",
    height: "38px",
    fontSize: 13,
    margin: "5px 3px",
    display: "inline-block",
  };

  const handleRowClick = (item) => {
    setImageData({
      photo: item.image_url,
      cameraName: item.camera_name,
      date: item?.last_active
        ? moment(item?.last_active).format("DD MMM, YYYY")
        : "",
      time: item?.last_active
        ? moment(item?.last_active).format("hh:mm A")
        : "",
    });
    setModalData(item);
    setShowModal(!showModal);
  };

  const handleAreaChange = (selected) => {
    const subareas = selected?.reduce((acc, currentArea) => {
      return [...acc, ...currentArea?.sub_area];
    }, []);
    setFilters({ ...filters, area: selected, sub_area: [] });
    setDropdownOptions({ ...dropdownOptions, sub_area_list: subareas });
  };

  const handleSubAreaChange = (selected) => {
    setFilters({ ...filters, sub_area: selected });
  };

  const handleSaveCamera = (record) => {
    fetchAllCameras();
  };


  const handleNavigation = () => {
    const queryString = new URLSearchParams({
      areas_list: JSON.stringify(dropdownOptions?.area_list), // Encode areas_list
      models: JSON.stringify(dropdownOptions?.module_options), // Encode models
      handleSave: handleSaveCamera, // Encode the function as a string (optional)
    }).toString();

    navigate(
      `${process.env.PUBLIC_URL}/addcameras/${JSON.parse(
        localStorage.getItem("role")
      )}?${queryString}`
    );
  };
  const handleToggle = () => {
    console.log(allCameras, 'ALL camera');
   
    const cameraIndex = allCameras.findIndex(item => item.camera_id === modalDataForToggle.id);
  
    if (cameraIndex !== -1) { 
      const newCameras = [...allCameras];
      newCameras[cameraIndex] = {
        ...newCameras[cameraIndex],
        active: !newCameras[cameraIndex].active 
      }; 
      setAllCameras(newCameras); 
      handleCameraStatus();
    } else {
      console.log('Camera not found');
    }
  }
  return (
    <>
      <br />
      <Container fluid>
        <Row style={{ marginBottom: 0, alignItems: "flex-start" }}>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 col-xxl-2">
            <h5 className="d-inline-block">Cameras</h5>
          </Col>
          <Col className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10">
            <div className="filter-row position-relative">
              {!area && <div style={{ display: "inline-block", position: "relative" }}>
                <ChevronDown
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "11px",
                    zIndex: "2",
                  }}
                  size={18}
                  color="#383e44"
                />
                <Typeahead
                  id="area-typeahead"
                  name="area"
                  className="custom-typeahead rounded-3"
                  labelKey="area"
                  multiple
                  options={dropdownOptions?.area_list}
                  selected={filters?.area}
                  onChange={handleAreaChange}
                  placeholder="Select Areas"
                  style={{
                    display: "inline-block",
                    textTransform: "capitalize",
                    maxWidth: "290px",
                    minWidth: "200px",
                  }}
                />
              </div>}

              <div style={{ display: "inline-block", position: "relative" }}>
                <ChevronDown
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "11px",
                    zIndex: "2",
                  }}
                  size={18}
                  color="#383e44"
                />

                <Typeahead
                  id="sub-area-typeahead"
                  name="sub-area"
                  className="custom-typeahead rounded-3"
                  labelKey="name"
                  multiple
                  options={dropdownOptions?.sub_area_list}
                  selected={filters?.sub_area}
                  onChange={handleSubAreaChange}
                  placeholder="Select Sub Areas"
                  style={{
                    display: "inline-block",
                    textTransform: "capitalize",
                    maxWidth: "290px",
                    minWidth: "200px",
                    fontSize: "11px",
                  }}
                />
              </div>

              <CommonFIlterButton
                data={CamersStatus}
                handleInputChange={handleFilterChange}
                style={style}
                selectedItem={filters?.status}
                firstOption={"Select Status"}
                inputChangeOption={"status"}
                className={""}
              />
              {JSON.parse(localStorage.getItem("role")) === "it-officer" && (
                <Button
                  className="rounded-3 d-inline-block"
                  color="primary"
                  style={{
                    height: "38px",
                    fontSize: 14,
                    margin: "5px 3px",
                    padding: "0px 10px",
                    width: "120px",
                  }}
                  onClick={handleNavigation}
                >
                  Add Cameras
                </Button>
              )}
            </div>
          </Col>
        </Row>

        {loading ? (
          <center
            style={{ textAlign: "center", marginTop: "", marginBottom: 40 }}
          >
            <Loader1 />
          </center>
        ) : showTable ? (
          <>
            <Row style={{ marginTop: 5 }}>
              <Col
                style={{ height: "137px" }}
                className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <KPICard
                  data={{
                    title: "Total Cameras",
                    gros: 50,
                    total: allCameras?.length || 0,
                    showPercentage: false,
                  }}
                  mainClass="kpi_card_total"
                />
              </Col>
              <Col
                style={{ height: "137px" }}
                className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
              >
                <KPICard
                  data={{
                    title: "Live Cameras",
                    gros:
                      allCameras?.length > 0
                        ? Math.round(
                          (allCameras?.filter((i) => i?.active === true)
                            ?.length /
                            allCameras?.length) *
                          100
                        ).toFixed(0)
                        : 0,
                    total:
                      allCameras?.filter((i) => i?.active === true)?.length ||
                      0,
                    showPercentage: true,
                  }}
                  mainClass="kpi_card_live"
                />
              </Col>
            </Row>
            <Row style={{ marginBottom: 20, marginTop: 5 }}>
              <Col>
                <CustomPagination
                  data={allCameras}
                  itemsPerPage={10}
                  renderItems={Items}
                />
              </Col>
              <ConfirmationModals toggle={handleCameraStatus} 
              modal={cameraStatusModal} 
              actionbtn={'Change status'} 
              body={'Are you sure you want to change this camera status ? '}
              user={modalDataForToggle}
              header={'Toggle Status'}
              handleConfirm={handleToggle}
              type={'status change'}
              
              />

              {/* custom pagination according to 20 objects */}
              {/* <CustomPaginationn/> */}
                  
            </Row>
          </>
        ) : (
          showError && <ErrorHeading />
        )}
        {showModal && (
          <ImageZoom
            photo={modalData?.image_url ? modalData?.image_url : CameraImage}
            setIsOpen={setShowModal}
            setShowModal={setShowModal}
            imageData={imageData}
            cameraTable={true}
          />
        )}
      </Container>
    </>
  );
};

export default AllCamerasScreen;