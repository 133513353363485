import React, { Fragment } from 'react'
import { Card, CardBody, CardHeader, Col, Container, Row } from 'react-bootstrap'
import DataTableModel from './Components/DataTable';
import AllFilters from '../../../Common/allFilters/AllFilters';
import { useState, useRef } from 'react';
import { shifts, weeks } from '../../../../Data/staticData/data';
import { H3, H4, H5 } from '../../../../AbstractElements';
import TotalAlerts from './Components/Charts/TotalAlerts';
import ModelChart from './Components/Charts/ModelChart';
import CameraImage from "../../../../assets/images/cameras/camera.jpeg"
import { useEffect } from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf'; 
import AIModal from './Components/Modal/AIModal';
import { area1, areaaa, dateChoose, SA, StaticDataForAO6 } from './Components/data/staticData';
import './custom.css'
import { toast } from "react-toastify";
import { getWeek } from '../../../../_helper/helper';
import { Typeahead } from 'react-bootstrap-typeahead';
import SingleImage from '../../../../Gallery/zoomin/SingleImage';
import DateFilter from './Components/Dates/DateFilter';
import ModelCards from './Components/AIModelCards/ModelCards';
import axios from 'axios';
import ImageZoom from '../../../Dashboards/AreaDashbaord/reports/Components/LiveAlertsCards/ImageZoom';
import html2pdf from 'html2pdf.js';
import AreaService from '../../../../api/areaService';
import Unilog from '../../../../assets/images/logo/uni-logo.jpeg'
import Disrupt from '../../../../assets/images/logo/disrupttt.png'
import PptxGenJS from "pptxgenjs";
import Loader1 from '../../../../CommonElements/Spinner/loader'
import ModelCardsForArea from './Components/AIModelCards/ModelCardsForArea';
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxReset } from "react-icons/rx";
import { Filter } from "react-feather";
const AIModelAndReportsScreen = ({area}) => {

  const today = new Date().toISOString().split("T")[0];
  const [isOpen, setIsOpen] = useState(false)
  const pageRef = useRef();
  const [modal, setModal] = useState(false);
  const [sub, setSubmit] = useState(false);
  const [currentWeek, setCurrentWeek] = useState()
  const [selectedOption, setSelectedOption] = useState('Daily');
  const style = {
    minWidth: "132px",
    width: '140px',
    maxWidth: "145px", height: "38px", fontSize: 13,
  }
  const monthtoday = new Date();
  const currentMonth = monthtoday.toISOString().slice(0, 7);
  const [dateShow, setDateShow] = useState(true)
  const [monthShow, setMonthShow] = useState(false)
  const [weeklyShow, setWeeklyShow] = useState(false)
  const [customDate, setCustomDate] = useState(false)
  const [cardsData, setCardsData] = useState([])
const [showButtons, setShowButtons] = useState(false)
const [loader, setLoader] = useState(true)
  const [chartsData, setChartsData] = useState({
    totalAlertsChart: [
     
    ],
    modelAccuracyChart: [
      { name: 'Helmet', value: 98 },
      { name: 'Vest', value: 85 },
      { name: 'Emergency Exit', value: 60 },
      { name: 'Machine Guard', value: 90 },
      { name: 'MMHE', value: 80 },
    ]
  })
  const filterCardRef = useRef(null);
  const filterButton = useRef(null);
  const [filters, setFilters] = useState({
    areas: [],
    shifts: [],
    date: today,
    week: '',
    month: '',
    starting: '',
    ending: ''
    // areas: [],
    // shifts: [],
    // date:today,
    // week:currentWeekk,
    // month:currentMonth,
    // starting:'',
    // ending:''
  })
  const leftChartRef = useRef(null);
  const rightChartRef = useRef(null);
const [showFilters, setShowFilters] = useState(false)
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        filterCardRef.current &&
        filterButton.current &&
        !filterCardRef.current.contains(event.target) &&
        !filterButton.current.contains(event.target)
      ) {
        setShowFilters(false);
      }
    }

    // Add event listener to detect clicks outside of the element
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilters]);

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();

    // Get the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);
    const pastDaysOfYear = (currentDate - firstDayOfYear) / 86400000;

    // Calculate the current week number
    const currentWeekNumber = Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);

    // Return the formatted value in YYYY-Wxx format
    return `${year}-W${currentWeekNumber.toString().padStart(2, '0')}`;
  };

  const currentWeekk = getCurrentWeek();
  const [isGenerating, setIsGenerating] = useState(false);

  const graphsContainerRef = useRef()

  let print = false

  function handleButton(type){
    if(type=='pdf'){
      print = false
    }
    else if(type=='print'){
      print = true
    }
    handlePdfDownload();
  }
 
  const handlePdfDownload = async () => {
    toast.success('Operation Loading');
    try {
      setIsGenerating(true);
  
      // Initialize PDF with better default font
      const pdf = new jsPDF('p', 'mm', 'a4');
      pdf.setFont('helvetica');
  
      // Page dimensions
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const margin = 15;
      let yOffset = margin;
  
      // Helper function to check page break
      const checkPageBreak = (requiredSpace) => {
        if (yOffset + requiredSpace > pageHeight - margin) {
          pdf.addPage();
          addHeaderFooter();
          yOffset = 40; // Start after header
        }
      };
  
      // Add header and footer to each page
      const addHeaderFooter = () => {
        pdf.setFillColor(255, 255, 255); // White background
        pdf.rect(0, 0, pageWidth, 25, 'F');
  
        // Add logos
        const logoHeight = 20;
        const logoWidth = 20;
        pdf.addImage(Unilog, 'JPEG', margin, 5, logoWidth, logoHeight); // UniLogo
        pdf.addImage(Disrupt, 'PNG', pageWidth - margin - 35, 5, 40, logoHeight); // Disrupt Icon
      };
  
      // Add first page header
      addHeaderFooter();
  
      // Main title
      yOffset = 40; // Start after header
      pdf.setFontSize(24);
      pdf.setTextColor(30, 41, 59); // slate-800
      pdf.setFont('helvetica', 'bold');
      pdf.text('AI Model Reports', pageWidth / 2, yOffset, { align: 'center' });
      yOffset += 20;
  
      // Selected Filters section
     // Selected Filters section
pdf.setFontSize(14);
pdf.setFont('helvetica', 'bold');
pdf.text('Selected Filters', margin, yOffset);
yOffset += 10;

// Display each filter in a cleaner format
pdf.setFontSize(11);
pdf.setFont('helvetica', 'normal');

// Iterate over each filter
Object.entries(filters).forEach(([key, value]) => {
  if (value.length > 0) {
    // Capitalize key and join array values if necessary
    const displayKey = key.charAt(0).toUpperCase() + key.slice(1);
    const displayValue = Array.isArray(value) ? value.join(', ') : String(value);

    // Add each filter entry as a separate line
    pdf.text(`${displayKey}: ${displayValue}`, margin + 5, yOffset);
    yOffset += 8;
  }
});
yOffset += 15;

  
      // Left Chart Section
      if (leftChartRef.current) {
        checkPageBreak(100); // Adjusted height check for chart
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Summary of Alerts', margin, yOffset);
        yOffset += 10;
  
        const canvas = await html2canvas(leftChartRef.current, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pageWidth - (2 * margin);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        pdf.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + 20;
      }
  
      // Right Chart Section
      if (rightChartRef.current) {
        checkPageBreak(100); // Adjusted height check for chart
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Accuracy of AI Models', margin, yOffset);
        yOffset += 10;
  
        const canvas = await html2canvas(rightChartRef.current, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pageWidth - (2 * margin);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        pdf.addImage(imgData, 'PNG', margin, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight + 20;
      }
  
      // Area Owner Details section
      // Area Owner Details section
checkPageBreak(40);
pdf.setFontSize(16);
pdf.setFont('helvetica', 'bold');
pdf.text('Area Owner Details', margin, yOffset);
yOffset += 15;

cardsData.forEach((area) => {
  checkPageBreak(60);

  const areaBoxWidth = pageWidth - (2 * margin);
  const areaBoxHeight = area.SubAreas.length * 25 + 30;
  let areaYPosition = yOffset;
  let isFirstPageForArea = true;

  // Function to add background and only area details on the first page
  const addAreaBackgroundAndDetails = (height, includeHeader = false) => {
    pdf.setFillColor(248, 250, 252);
    pdf.roundedRect(margin, areaYPosition, areaBoxWidth, height, 3, 3, 'F');

    if (includeHeader) {
      // Area Name
      pdf.setFontSize(14);
      pdf.setFont('helvetica', 'bold');
      pdf.text(`Area: ${area.AreaName}`, margin + 5, areaYPosition + 10);

      // Owner Name
      pdf.setFontSize(12);
      pdf.setFont('helvetica', 'normal');
      pdf.text(`Owner: ${area.AreaOwner}`, margin + 5, areaYPosition + 15);

      isFirstPageForArea = false; // Set to false after adding header for the first time
    }
  };

  // Draw initial area box with header details
  addAreaBackgroundAndDetails(areaBoxHeight, isFirstPageForArea);

  // Adjust initial sub-area position based on header height
  let subAreaOffset = areaYPosition + (isFirstPageForArea ? 35 : 15);

  area.SubAreas.forEach((subArea) => {
    // Check for page break before adding the next sub-area
    if (subAreaOffset + 40 > pageHeight - margin) {
      pdf.addPage();
      addHeaderFooter();
      areaYPosition = 40;
      subAreaOffset = areaYPosition + 15;

      // Redraw background on new page without header
      addAreaBackgroundAndDetails(areaBoxHeight, false); // Background only, no header
    }

    // Add sub-area name
    pdf.setFont('helvetica', 'bold');
    pdf.text(
      `• ${subArea.SubAreaName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}`,
      margin + 10,
      subAreaOffset + 10
    );
    

    // Add Cameras and Compliance details below the sub-area name
    pdf.setFont('helvetica', 'normal');
    pdf.text(`Cameras: ${subArea.Cameras || subArea.Cmaeras}`, margin + 15, subAreaOffset + 16);
    pdf.text(`Compliance: ${subArea.Compliance || 'N/A'}`, margin + 15, subAreaOffset + 22);

    subAreaOffset += 25; // Move Y position down for the next sub-area
  });

  yOffset = subAreaOffset + 10; // Adjust Y for the next area section
});





  
      // Save or Print the PDF
      const pdfBlob = pdf.output('blob');
      if (print) {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const newWindow = window.open(pdfUrl);
        newWindow.onload = () => {
          newWindow.print();
        };
      } else {
        pdf.save('AI_Model_Reports.pdf');
      }
  
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setIsGenerating(false);
    }
  };
  
  
  
  
  
  const handlePrint = () => {
    handlePdfDownload(true); // Pass `true` to print the PDF directly
  };
  

  function toggleModal() {
    setModal(!modal);
    console.log("modal", modal);
    setSubmit(false);
  }



  const handleDateDrop = (e) => {
    setSelectedOption(e.target.value); // Update the dropdown value based on user selection
    if (e.target.value == 'Duration') {
      setMonthShow(false)
      setDateShow(false)
      setWeeklyShow(false)
      setCustomDate(false)
    }
    else if (e.target.value == 'Month') {
      setMonthShow(true)
      setDateShow(false)
      setWeeklyShow(false)
      setCustomDate(false)

    }
    else if (e.target.value == 'Daily') {
      setMonthShow(false)
      setDateShow(true)
      setWeeklyShow(false)
      setCustomDate(false)
    }
    else if (e.target.value == 'Week') {
      setMonthShow(false)
      setDateShow(false)
      setWeeklyShow(true)
      setCustomDate(false)
    }
    else if (e.target.value == 'Custom') {
      setMonthShow(false)
      setDateShow(false)
      setWeeklyShow(false)
      setCustomDate(true)
    }
  };

  const [identifier, setIdentifier] = useState('')

  // To handle changes in the Typeahead
  const handleInputChange = (e, field) => {
    setShowButtons(true)
    let value = e.target.value;
console.log('field and value',field,value)
    if (field === "week" && value) {
      // When a week is selected, clear both date, month, and custom date range fields
      setIdentifier('week')
      setFilters((prevFilters) => ({
        ...prevFilters,
        week: value,
        date: '',  // Clear the date
        month: '', // Clear the month
        starting: '', // Clear starting date
        ending: '',   // Clear ending date
      }));
    } else if (field === "month" && value) {
      setIdentifier('month')
      setFilters((prevFilters) => ({
        ...prevFilters,
        month: value,
        date: '', // Clear the date when a new month is selected
        week: '', // Clear the week when a new month is selected
        starting: '', // Clear starting date
        ending: '',   // Clear ending date
      }));
    } else if (field === "date" && value) {
      setIdentifier('date')
      setFilters((prevFilters) => ({
        ...prevFilters,
        date: value,
        month: '', // Clear the month when a new date is selected
        week: '',  // Clear the week when a new date is selected
        starting: '', // Clear starting date
        ending: '',   // Clear ending date
      }));
    } else if (field === "starting" || field === "ending") {
      // When a custom date range is selected, clear date, month, and week
      setIdentifier('custom')
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: value,
        date: '',  // Clear the date
        month: '', // Clear the month
        week: '',  // Clear the week
      }));
    } else {
      // Default case for other filters
      setFilters((prevFilters) => ({
        ...prevFilters,
        [field]: value,
      }));
    }
  };
  const typeHeadChange = (field, selected) => {
    setShowButtons(true)
    setFilters({
      ...filters,
      [field]: selected  // Update the field dynamically (factories or areas)
    });
  };



  const addRandomCompliance = (areas) => {
    return areas?.map(area => ({
      ...area,
      SubAreas: area?.SubAreas?.map(subArea => ({
        ...subArea,
        Compliance: Math.floor(Math.random() * (100 - 50 + 1)) + 50 // Generate a random number between 50 and 100
      }))
    }));
  };

  // Adding random Compliance to area1 data
  const updatedArea1 = addRandomCompliance(area1);
  console.log('area', area1)
  console.log('updatedArea1', updatedArea1)

  const [imageData, setImageData] = useState({});

  const [showModal, setShowModal] = useState(false)

  function handleCardClick(item) {
    console.log('this is item', item)
    setImageData({
      cameraName: undefined,
      violation: item.violation,
      areaName: item.AreaName,
      areaOwner: item.AreaOwner,
      subareas: item.SubAreas,
      date: item?.date,
      time: item?.time,
    });
    setShowModal(!showModal)
  }

 

  const [lastFilter, setLastFilter] = useState(null);
  // const defaultData = chartsData.totalAlertsChart
  const [alertsData, setAlertsData] = useState([]);
  const [totalAlerts, setTotalAlerts] = useState()
  const [series2, setSeries2] = useState([]);
  const [reset, setReset] = useState(false)

  useEffect(() => {

    const formatDate = (dateStr) => {
      const [year, month, day] = dateStr.split('-');
      return `${month}/${day}/${year}`;
    };
    const payload = {
      safety_area: filters.areas ? filters.areas : ['AO-6','AO-7','AO-9'],
      shift: filters.shifts ? filters.shifts : ['Shift A', 'Shift B', 'Shift C'],
      start_date: formatDate(today),
      end_date:  formatDate(today)
    };
    const payload2 = {
      safety_area: filters.areas ? filters.areas : ['AO-6','AO-7','AO-9'],
      shift: filters.shifts ? filters.shifts : ['Shift A', 'Shift B', 'Shift C'],
      start_date: formatDate(today),
      end_date:  formatDate(today)
    };
    console.log('useeffect payload', payload)
    const payload1 = {
      safety_area: filters.areas ? filters.areas : ['AO-6','AO-7','AO-9'],
      shift: filters.shifts ? filters.shifts : ['Shift A', 'Shift B', 'Shift C'],
      start_date: "",
      end_date: "",
      week:"",
      month: filters.month ? filters.month : currentMonth
    };
  ModelAccuracyChart(payload2)
 getAlertsCharts(payload)
 console.log('useeffecttttttt', alertsData)
 getAiReportsCardsFunc(payload1)
 
    window.scrollTo({
      top: 0, // Scroll to the top
      left: 0, // Ensure it's the horizontal start as well
      behavior: 'smooth' // Smooth scrolling behavior
    });
  }, [reset])

  //api calls
const [modelaccuracy, setmodelaccuracy] = useState(0)

  async function ModelAccuracyChart(payload) {
    const res = await AreaService.getModelAccuracyChart(payload);
    if(res){
      setLoader(false)
    }
    console.log('these are models', res?.data);
  
    let modifiedData = res?.data?.totalAlertsChart.map(item => {
      // Modify the names as required
      if (item.name === 'forklift_person_in_same_aisle') {
        item.name = 'MMHE';
      } else if (item.name === 'emergency_exit_blockage') {
        item.name = 'Emergency Exit';
      } else if (item.name === 'machine_guard_open') {
        item.name = 'Machine Guard';
      }
  
      return item;
    });
    const totalValues = modifiedData.reduce((acc, item) => acc + item.value, 0);
    const average = totalValues / modifiedData.length;
    const roundedAverage = Math.round(average);
    // Set the average to the state
    setmodelaccuracy(roundedAverage);
    // Set the modified data in setSeries2
    if(area) {
      modifiedData = modifiedData?.filter((dt)=> dt.name.toLowerCase() !== 'emergency exit' && dt.name.toLowerCase() !== 'machine guard' )
    }
    setSeries2(modifiedData);
  }
  

  const [allData, setAllData] = useState([])

  async function getAiReportsCardsFunc(payload){
    const res = await AreaService.getAiReportsCards(payload)
    if (res) {
      setLoader(false);
    }
    let updatedData = res?.data;
    if (area) {
      updatedData = updatedData?.filter((dt) => dt?.AreaName?.toLowerCase() === 'ao-6');
    }
    
    // Sort updatedData by AreaName in ascending order (AO-1, AO-2, ...)
    updatedData = updatedData?.sort((a, b) => {
      const numA = parseInt(a.AreaName.split('-')[1], 10);
      const numB = parseInt(b.AreaName.split('-')[1], 10);
      return numA - numB;
    });
    
    setAllData(updatedData);
  }
    
 
  console.log('all Data=>', allData)

  async function getAlertsCharts(payload){
   const res = await AreaService.getAlertsChart(payload)
   if(res){
    setLoader(false)
  }
   const desiredOrder = ["Emergency Exit", "MMHE", "Helmet", "Vest", "Machine Guard"];
   let sortedData = res?.data?.totalAlertsChart?.sort((a, b) => {
    return desiredOrder.indexOf(a?.category) - desiredOrder.indexOf(b?.category);
  });
  if(area){
    sortedData = sortedData?.filter((it)=> it.category.toLowerCase() !== 'emergency exit' && it.category.toLowerCase() !== 'machine guard')
  }
  const totAlerts = sortedData?.reduce((acc, curr)=> acc + curr.value, 0)
  setTotalAlerts(totAlerts)
  setAlertsData(sortedData)

}

  async function ApplyFilter() {


    const formatDate = (dateStr) => {
      const [year, month, day] = dateStr.split('-');
      return `${month}/${day}/${year}`;
    };
    const payload = {
      safety_area: filters.areas ,
      shift: filters.shifts ,
      start_date: filters.starting!=='' ? formatDate(filters.starting) : filters.date=='' ? '' : formatDate(filters.date),
      end_date: filters.ending!=='' ? formatDate(filters.ending) : filters.date=='' ? '' : formatDate(filters.date),
      week: filters.week,
      month:filters.month
    };
    
    console.log('this is payload going', payload)
 getAlertsCharts(payload)
 ModelAccuracyChart(payload)
 getAiReportsCardsFunc(payload)
  }

  const shouldShowButton = () => {
    return (
      filters.areas.length > 0 ||
      filters.shifts.length > 0 ||
      filters.date !== today ||
      filters.week !== '' ||
      filters.month !== '' ||
      filters.starting !== '' ||
      filters.ending !== ''
    );
  };

 
  function Reset() {
    setShowButtons(false)
    setReset(!reset)
    setMonthShow(false)
    setWeeklyShow(false)
    setCustomDate(false)
    setSelectedOption('Daily')
    setDateShow(true)

  setFilters({
      areas: [],
      shifts: [],
      date: today,
      week: '',
      month: '',
      starting: '',
      ending: ''
    })
    const formatDate = (dateStr) => {
      const [year, month, day] = dateStr.split('-');
      return `${month}/${day}/${year}`;
    };
    
    // const payload = {
    //   safety_area: filters.areas ? filters.areas : ['AO-6','AO-7','AO-9'],
    //   shift: filters.shifts ? filters.shifts : ['Shift A', 'Shift B', 'Shift C'],
    //   start_date: formatDate(today),
    //   end_date:  formatDate(today)
    // };
    // const payload2 = {
    //   safety_area: filters.areas ? filters.areas : ['AO-6','AO-7','AO-9'],
    //   shift: filters.shifts ? filters.shifts : ['Shift A', 'Shift B', 'Shift C'],
    //   start_date: formatDate(today),
    //   end_date:  formatDate(today)
    // };
    // console.log('useeffect payload', payload)
    // const payload1 = {
    //   safety_area: filters.areas ? filters.areas : ['AO-6','AO-7','AO-9'],
    //   shift: filters.shifts ? filters.shifts : ['Shift A', 'Shift B', 'Shift C'],
    //   start_date: "",
    //   end_date: "",
    //   week:"",
    //   month: filters.month ? filters.month : currentMonth
    // };
    // getAiReportsCardsFunc(payload1)
    // getAlertsCharts(payload)
    // ModelAccuracyChart(payload2)
  }


  const [email, setEmail] = useState('')

  const handlePPT = async () => {
    const pptx = new PptxGenJS();
    const slideHeightLimit = 6; // Limiting height on each slide to avoid overflow
  
    // Slide 1: Heading and Logos
    const slide1 = pptx.addSlide();
    slide1.background = { color: "FFFFFF" };
  
    // slide1.addText("AI Model Reports", {
    //     // Center on a 5.63" tall slide
    //   fontSize: 24,
    //   color: "1E293B",
    //   bold: true,
    //   align:'center',
    //   valign:'middle',
    //   w:'100%',
    //   height:'100%'
     
    
    // });
    slide1.addText(
      [
          {
              text: "AI Model Reports",
              options: { fontSize: 24,
                  color: "1E293B",
                  bold: true,
                  align:'center',
                  valign:'middle', },
          },
      ],
      // { x: 1.0, y: 2.5, w: 5, h: 1 }
      {w:'100%',h:'100%'}
  );
    
  
    // Add Logos
    slide1.addImage({
      path: Unilog,
      
      w: '15%',
      h: 1,
    });
    slide1.addImage({
      path: Disrupt,
     
      w: '25%',
      x:'73%',
      h: 1,
    });
  
    // Slide 2: Selected Filters
    const slide2 = pptx.addSlide();
    slide2.background = { color: "FFFFFF" };
  
    slide2.addText("Selected Filters", {
      x: 0.5,
      y: 0.5,
      fontSize: 20,
      color: "1E293B",
      bold: true,
    });
  
    let yPosition = 1; // Starting Y position for filter details
    Object.entries(filters).forEach(([key, value]) => {
      const filterText = Array.isArray(value) && value.length === 0
      ? 'N/A'
      : value
      ? Array.isArray(value)
        ? value.join(", ")
        : String(value)
      : 'N/A';
    

      slide2.addText(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${filterText}`, {
        x: 0.5,
        y: yPosition,
        fontSize: 14,
        color: "333333",
      });
      yPosition += 0.5;
    });
  
    // Slide 3: Left Chart Snapshot
    const slide3 = pptx.addSlide();
    slide3.addText("Summary of Alerts", {
      x: 1,
      y: 0.5,
      fontSize: 20,
      color: "1E293B",
      bold: true,
    });
  
    if (leftChartRef.current) {
      const canvas = await html2canvas(leftChartRef.current, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      slide3.addImage({ data: imgData, x: 0.5, y: 1, w: 8, h: 4.5 });
    }
  
    // Slide 4: Right Chart Snapshot
    const slide4 = pptx.addSlide();
    slide4.addText("Accuracy of AI Models", { x: 1, y: 0.5, fontSize: 20, color: "1E293B", bold: true });
  
    if (rightChartRef.current) {
      const canvas = await html2canvas(rightChartRef.current, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");
      slide4.addImage({ data: imgData, x: 0.5, y: 1, w: 8, h: 4.5 });
    }
  
    // Additional slides for each card in cardsData
    cardsData.forEach((card, index) => {
      let slide = pptx.addSlide();
      slide.background = { color: "FFFFFF" };
  
      // Display area and owner information
      slide.addText(`Area: ${card.AreaName}`, { x: 0.5, y: 0.5, fontSize: 20, color: "1E293B", bold: true });
      slide.addText(`Owner: ${card.AreaOwner}`, { x: 0.5, y: 1, fontSize: 16, color: "333333" });
  
      // SubAreas section
      let yPosition = 1.5; // Start position for subareas
      card.SubAreas.forEach((subArea) => {
        if (yPosition + 1 > slideHeightLimit) { // Check if new slide is needed
          slide = pptx.addSlide();
          yPosition = 0.5; // Reset y position for new slide
        }
  
        slide.addText(`SubArea: ${subArea.SubAreaName}`, { x: 0.5, y: yPosition, fontSize: 14, color: "333333" });
        yPosition += 0.3;
  
        slide.addText(`Cameras: ${subArea.Cameras || subArea.Cmaeras}`, { x: 1, y: yPosition, fontSize: 12, color: "666666" });
        yPosition += 0.3;
  
        slide.addText(`Compliance: ${subArea.Compliance || 'N/A'}`, { x: 1, y: yPosition, fontSize: 12, color: "666666" });
        yPosition += 0.5; // Space between subareas
      });
    });
  
    pptx.writeFile("AI_Model_Report_Presentation.pptx");
  };
  
  
  
  
  
  

  return (
    <Fragment>
      {isOpen && <SingleImage photo={CameraImage} isOpen={isOpen} setIsOpen={setIsOpen} />}
      <br />

      <div className="p-0 m-0" ref={pageRef}>
        <Container fluid={true}>
          {showModal && (
            <ImageZoom
              // photo={modalData?.image}
              setShowModal={setShowModal}
              photo={CameraImage}
              setIsOpen={setShowModal}
              imageData={imageData}
            />
          )}
          {
            loader ? (
              <>
              <Loader1 />
              </>
            )
            : (
              <>
              
           
          <Row className={` my-0 d-flex align-items-start px-1`}>
            <Col  xl='6' lg='6' md='6' sm='6' xs='12'>
              <h4 style={{ fontSize: '20px' }}>AI Model and Reports </h4>
            </Col>
            <Col style={{ flexGrow: 0 }} className={` d-flex flex-wrap  justify-content-start justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-end  `} xl='6' lg='6' md='6' sm='6' xs='12'>
            <div type='button' className={`d-flex justify-content-center filter-btnn  ${showFilters && 'border_R'}`} 
                      ref={filterButton}
                      onClick={()=> setShowFilters(!showFilters)}
                      >
                      <p
                        className="m-0"
                        style={{ fontSize: "16px",  }}
                      >
                        Filters 
                      </p>
                      <span className="d-flex"><Filter color="#fff" size={16} className="ms-2 " /></span>
                      </div>





                      
             
            



<div className="w-100 d-flex justify-content-end position-relative">
                      {showFilters && <div style={{zIndex:'2'}} className={`d-flex align-items-center justify-content-end gap-2 py-3 filter-card shadow-sm`}
                      ref={filterCardRef}
                      >

                        <div  className="">
                        <DateFilter allData={allData} style={style} shouldShowButton={shouldShowButton} selectedOption={selectedOption} currentWeek={currentWeekk} typeHeadChange={typeHeadChange} filters={filters} handleDateDrop={handleDateDrop} handleInputChange={handleInputChange} dateShow={dateShow} monthShow={monthShow} weeklyShow={weeklyShow} customDate={customDate} />
                          
                        {showButtons && (
                <>
                  
              
                    <Button
                                  style={style}
                                  className={`mx-2 p-0 rounded-3 shadow-sm d-flex align-items-center justify-content-evenly`}
                                  onClick={ApplyFilter}
                                  color=""
                                >
                                  <IoCheckmarkOutline
                                    style={{
                                      color: '#22c65e',
                                      fontSize: "20px",
                                      transform: "rotate(20deg)",
                                    }}
                                  />
                                  <p style={{ color: '#22c65e' }} className="m-0 p-0 "> Accept</p>
                                </Button>
                                <Button
                                  style={style}
                                  className={`mx-2 mt-3 rounded-3 shadow-sm d-flex align-items-center justify-content-evenly`}
                                  onClick={Reset}
                                  color=""
                                >
                                  <RxReset
                                    style={{
                                      color: '#4e74d4',
                                      fontSize: "20px",
                                      // transform: "rotate(20deg)",
                                    }}
                                  />
                                  <p style={{ color: '#4e74d4' }} className="m-0 p-0 "> Reset</p>
                                </Button>
                 
                </>
              )}

                        </div>
                      </div>}
                    </div>







            </Col>
          </Row>
          <Card className='p-0 mx-1 mt-2 '>
            <CardBody className='p-0'>
              <CardHeader className=''>
                <Row className='p-0  m-0 d-flex align-items-center justify-content-between'>
                  <Col xl={3} lg={3} md={3} sm={12} xs={12} className='p-0 m-0'>
                    <H5 attrH5={{ style: { fontSize: '16px' },  }}>Module Analytics</H5>
                    <H5 attrH5={{ style: { fontSize: '15px', fontWeight:'100' }, className:'f-light my-1'}}>Total Alerts: <span style={{fontWeight:'600'}}>{totalAlerts}</span></H5>
                    <H5 attrH5={{ style: { fontSize: '15px', fontWeight:'100' }, className:'f-light'}}>Total Accuracy: <span style={{fontWeight:'600'}}>{modelaccuracy}</span></H5>
                  </Col>
                  <Col xl={9} lg={9} md={9} sm={12} xs={12} className='p-0 mx-0 my-2 my-xl-0 my-lg-0 my-md-0 my-sm-0 gap-2 d-flex justify-content-xl-end justify-content-lg-end justify-content-md-end justify-content-sm-start justify-content-start' >

                    <button className='btn btn-outline-danger ai-mod-sec-buttons' onClick={()=>handleButton('pdf')}>PDF</button>
                    <button className='btn btn-outline-primary ai-mod-sec-buttons' onClick={toggleModal} >Email</button>
                    <button className='btn btn-outline-success ai-mod-sec-buttons' onClick={()=> handleButton('print')}>Print</button>
                    <button className='btn btn-outline-warning ai-mod-sec-buttons' onClick={handlePPT} >PPT</button>

                  </Col>
                </Row>
              </CardHeader>
              <Row ref={graphsContainerRef} className='d-flex align-items-end graphs-container'>
                <Col ref={leftChartRef} className='m-0' xl="6" lg="6" md="12" sm="12" xs="12">
                  <Card className='m-0'>
                    <CardBody className='m-0'>
                    {
  alertsData.some((v) => v.value !== 0) ? (
    <TotalAlerts defaultData={chartsData.totalAlertsChart} data={alertsData} setData={setAlertsData} lastFilter={lastFilter} setLastFilter={setLastFilter} chartsData={chartsData} filters={filters} />
  ) : (
    <div className="d-flex align-items-center justify-content-center">
    <img style={{ height: '300px', width: '300px', mixBlendMode: 'multiply' }} src="https://img.freepik.com/premium-vector/search-found-no-data-found-data-empty_1249780-8.jpg" />
    <H4>No Data Found</H4>
  </div>
  )
}
                     
                    </CardBody>
                  </Card>
                </Col>
                <Col ref={rightChartRef} className='mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3' xl="6" lg="6 " md="12" sm="12" xs="12">
                  <Card className='m-0'>
                    <CardBody className='m-0'>
                    {
  series2.some((v) => v.value !== 0) ? (
    <ModelChart 
      series2={series2} 
      setSeries2={setSeries2} 
      lastFilter={lastFilter} 
      setLastFilter={setLastFilter} 
      chartsData={chartsData} 
      filters={filters} 
    />
  ) : (
    <div className="d-flex align-items-center justify-content-center">
    <img style={{ height: '300px', width: '300px', mixBlendMode: 'multiply' }} src="https://img.freepik.com/premium-vector/search-found-no-data-found-data-empty_1249780-8.jpg" />
    <H4>No Data Found</H4>
  </div>
  )
}

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row className='px-1'>
            <Col className="mb-2" xl="12" lg="12" md="12" sm="12" xs="12">
            {
              allData.length > 0 ? (

                <ModelCards areaD={area ? true : false} allData={area ? StaticDataForAO6 : allData} setCardsData={setCardsData} data={cardsData} handleCardClick={handleCardClick} />
              ) 
              : (
                <div className="d-flex align-items-center justify-content-center">
    <img style={{ height: '300px', width: '300px', mixBlendMode: 'multiply' }} src="https://img.freepik.com/premium-vector/search-found-no-data-found-data-empty_1249780-8.jpg" />
    <H4>No Data Found</H4>
  </div>
              )
            }
            </Col>
          </Row>
          </>
            )
          }
          <AIModal
            email={email}
            setEmail={setEmail}
            modal={modal}
            toggleModal={toggleModal}
            sub={sub}
            setSubmit={setSubmit}
          />
        </Container>
      </div>
    </Fragment>
  );
};

export default AIModelAndReportsScreen;
