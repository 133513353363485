import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

export default function ModelChart({ series2,setSeries2, filters, chartsData, lastFilter, setLastFilter }) {
    console.log('series2', series2)
    // Updated series2 with object format
    
    const nameAbbreviations = {
        "Helmet": "Helmet",
        "Vest": "Vest",
        "MMHE": "MMHE",
        "Emergency Exit": "E. Exit",
        "Machine Guard": "M. Guard"
    };

    // Update data based on the latest filter (either shift or week)
     // Update when either filters or lastFilter change

    // Detect and set the latest applied filter
    useEffect(() => {
        setLastFilter('shifts'); // Set 'shifts' as the latest filter
    }, [filters?.shifts]);

    useEffect(() => {
        setLastFilter('areas'); // Set 'areas' as the latest filter
    }, [filters?.areas]);
    useEffect(() => {
        // Update the data state with new values
      }, [lastFilter, filters]);
    // Convert the series2 data from object format to the format ApexCharts expects

   
    // const formattedSeriesData = [
    //     {
    //         name: 'Accuracy',
    //         data: series2.map(item => item.value), // Extract the values for the chart
    //     },
    // ];
    const formattedSeriesData = [
        {
            name: '80 > value',
            data: series2?.map(item =>
                item?.value < 80 ? item?.value : null  // Only show values 80 > value for 80 > value
            ),
            color: '#ef4343'  // Red for 80 > value
        },
        {
            name: '95> value > 80',
            data: series2?.map(item =>
                item?.value >= 80 && item?.value <= 95 ? item?.value : null  // Only show values between 80 and 95 for 95> value > 80
            ),
            color: '#f9c50a'  // Yellow for 95> value > 80
        },
        {
            name: '95 < value',
            data: series2?.map(item =>
                item?.value > 95 ? item?.value : null  // Only show values above 95 for 95 < value
            ),
            color: '#32CD32'  // Green for 95 < value
        }
    ];
    

    const secondOptions = {
        chart: {
            type: 'bar',
            width: '100%',
            stacked:true,
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                autoSelected: 'zoom'
            },
        },
        dataLabels: {
            enabled: true,  // Enable data labels
             // Position labels at the top of the bars
            style: {
                colors: ['#ffffff'],  // Optionally set the color of the labels
                fontSize: '11px',  // Adjust font size for readability
                fontWeight: 'normal',  
            
            },
            formatter: function (val, opts) {
                return `${val}%`
            },
            textAnchor: 'middle',
        },
        xaxis: {
            categories: series2?.map(item =>   nameAbbreviations[item?.name] || item?.name), // Extract the names for the x-axis categories
            labels: {
                style: {
                    fontSize: '12px', // Set a default font size
                },
                rotateAlways: false,
                hideOverlappingLabels: false,
                trim: true, // Trim labels if they are too long
            },
        },
     
        yaxis: {
            title: {
                text: 'Accuracy (%)',
                // offsetY: -150, // Position title at the top
              // Keep the title horizontal
                style: {
                    fontSize: '13px',
                    fontWeight: '100',
                },
              
            },
            labels: {
                style: {
                    fontSize: '12px', // Set a default font size
                },
      
            },
          
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '65%', // Reduce the width of the bars to fit more categories
                borderRadius: 4,
                colors: {
                    ranges: [
                        {
                            from: 0,
                            to: 79,
                            color: '#ef4343' // Red for values 80 > value
                        },
                        {
                            from: 80,
                            to: 95,
                            color: '#f0ab43' // Yellow for values between 80 and 95
                        },
                        {
                            from: 96,
                            to: 100,
                            color: '#43f06e' // Green for values above 95
                        }
                    ]
                }
            },
        },
        fill: {
            colors: ['#ff0000', '#f9c50a', '#32CD32', '#FFD700', '#9370DB'], // Default colors (not applied to bars individually)
        },
     
        title: {
            text: 'Accuracy of AI Models',
            align: 'center',
            style: {
                fontSize: '14px',
                fontWeight: '500',
            },
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            labels: {
                colors: ['#FF6347', '#FFD700', '#32CD32'],
                useSeriesColors: true
            },
            markers: {
                fillColors: ['#ff0000', '#f9c50a', '#32CD32'],
            },
            formatter: function(seriesName, opts) {
                const severityLevels = {
                    0: '80 > value',
                    1: '95> value > 80',
                    2: '95 < value',
                };
                return severityLevels[opts.seriesIndex];
            },
        },
        responsive: [
            {
                breakpoint: 1367,
                options: {
                    chart: {
                        width: '100%',
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '12px', // Adjust font size for smaller screens
                            },
                            rotate: -45, // Rotate labels for better fit
                        },
                    },
                }
            },
            {
                breakpoint: 992,
                options: {
                    chart: {
                        height:400,
                    },
                   
                }
            },
         
                  {
                breakpoint: 697,
                options: {
                    chart: {
                        width: '100%',
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '12px', // Adjust font size for smaller screens
                            },
                            rotate: -45, // Rotate labels for better fit
                        },
                    },
                }
            },
            {
                breakpoint: 620,
                options: {
                    chart: {
                        width: '100%',
                    },
                    xaxis: {
                        labels: {
                            style: {
                                fontSize: '10px', // Adjust font size for smaller screens
                            },
                            rotate: -45, // Rotate labels for better fit
                        },
                    },
                   
                    dataLabels: {
                        style: {
                            fontSize: '9px',  // Adjust font size for readability
                        },
                    },
                }
            }
        ]
    };

    return (
        <>
            <ReactApexChart
                options={secondOptions}
                series={formattedSeriesData} // Updated series with new data format
                type="bar"
                height={350}
                width={secondOptions.chart.width}
            />
            
        </>
    );
}
