import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { CiUser } from "react-icons/ci";
import { FaCamera } from "react-icons/fa";
const DetailCard = ({items}) => {
    return (
        <>
            {/* <Card style={{ background:items<50?'#ffe8e8':items>=50 && items<=80?'#edf3ff':'#edffed'}}> */}
            <Card>
                <CardBody >

                    <div className='mt-2 d-flex ' style={{ fontSize: '15px', }}>
                        <CiUser style={{ fontWeight: 'bold', fontSize: '25px' }} />
                         <h4 className='mx-1'>Area owner</h4>
                    </div>
                    <p className='mb-4' style={{ fontSize: '15px', color: 'grey' }}>A0-6</p>
                    <Row>
                        <Col xs='6' className='d-flex align-items-center'>
                            <FaCamera style={{ fontWeight: 'bold', fontSize: '20px',color:'#3B82F6' }}/>
                            <p className='mx-1' style={{fontSize:'22px',fontWeight: '600'}}>5</p>
                        </Col>
                        <Col xs='6' className='text-end'>
                        <p className='m-0 p-0'>Compliance</p>
                            <h4  style={{ fontWeight: 'bold', fontSize: '20px' }}>50%</h4>

                            
                        </Col>
                        <Col>
                        <div style={{width:'100%', background:'#eaeaea',borderRadius:'3px'}} className='mt-2 p-0'>
                                <div style={{width:'70%',height:'8px',background:items<50?'red':items>=50 && items<=80?'#3B82F6':'Green',borderRadius:'3px'}}>

                                </div>
                            </div>
                        </Col>
                    </Row>


                </CardBody>
            </Card>
        </>
    );
}

export default DetailCard;
