import React, { useState } from 'react';
import { Card, CardBody, Button, Col, Row, CardHeader } from 'react-bootstrap';
import { ImCheckboxChecked } from "react-icons/im";
import { TbClockCancel } from "react-icons/tb";
import { FaRegHourglass } from 'react-icons/fa';
import '../style/style.css';

const PreviousSupportTicketCard = ({ dataa, handleEditTicket, ticketno, itmode, toggle }) => {
    const [showAllModel, setShowAllModel] = useState(false);
    const [showAllArea, setShowAllArea] = useState(false);
    const [showAllSubArea, setShowAllSubArea] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const toggleModel = () => setShowAllModel(!showAllModel);
    const toggleArea = () => setShowAllArea(!showAllArea);
    const toggleSubArea = () => setShowAllSubArea(!showAllSubArea);

    const renderShortened = (text, limit = 10) => {
        if (text.length <= limit) return text;
        return `${text.substring(0, limit)}...`;
    };
console.log(dataa,'data for mapping')
    return (
        <Card className='ribbon-wrapper p-0 PreviousCard' style={{ minHeight: '290px' }}>
            <CardHeader className={`${dataa.status === 'resolved' ? 'b-l-success b-r-success b-t-success border-3' : dataa.status === 'in process' ? 'b-l-warning b-r-warning b-t-warning border-3' : 'b-l-danger b-r-danger b-t-danger border-3'}`}>
                <div className='d-flex justify-content-between flex-wrap'>
                    <h6>Ticket ID. {dataa.id}</h6>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <h6 style={{ paddingRight: '10px' }}>{dataa.status}</h6>
                        {dataa.status === 'resolved' ?
                            <ImCheckboxChecked style={{ color: '#91c837', background: 'white', fontSize: '18px', borderRadius: '3px', boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.5)' }} className='m-0 p-0' />
                            : dataa.status === 'pending' ? <TbClockCancel style={{ color: '#FC4438', fontSize: '18px', borderRadius: '3px' }} className='m-0 p-0' /> :
                                dataa.status === 'in process' ? <FaRegHourglass style={{ color: '#ffc107', fontSize: '18px', borderRadius: '3px' }} className='m-0 p-0' /> : null
                        }
                    </div>
                </div>
            </CardHeader>
            <CardBody className='previousCardStyle'>
                <Row>
                    <Col sm='6' xs='12' >
                        <ul>
                            <li>
                                Model | <b>{showAllModel ? dataa.models.map(model => model.name).join(' | ') : renderShortened(dataa.models.map(model => model.name).join(' | '))}</b>

                                {dataa.models.map(model => model.name).join(' | ').length > 10 && (
                                    <a onClick={toggleModel} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                        {showAllModel ? ' See Less' : ' See More'}
                                    </a>
                                )}
                            </li>
                            <li>
                                Generated by | <b>{dataa.owner}</b>
                            </li>
                            <li>
                                {/* Area | <b>{showAllArea ? dataa.areas.map(area => `${area.area_name} (${area.owner})`).join(' | ') : renderShortened(dataa.areas.map(area => `${area.area_name} (${area.owner})`).join(' | '))}</b>
                                {dataa.areas.map(area => `${area.area_name} (${area.owner})`).join(' | ').length > 10 && (
                                    <a onClick={toggleArea} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                        {showAllArea ? ' See Less' : ' See More'}
                                    </a>
                                )} */}
                                Area | <b>{showAllArea ? dataa.areas.map(area => `${area.area_name}`).join(' | ') : renderShortened(dataa.areas.map(area => `${area.area_name} `).join(' | '))}</b>
                                {dataa.areas.map(area => `${area.area_name}`).join(' | ').length > 10 && (
                                    <a onClick={toggleArea} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                        {showAllArea ? ' See Less' : ' See More'}
                                    </a>
                                )}
                            </li>
                            <li>
                                Sub Area | <b>{showAllSubArea ? dataa.sub_areas.map(subArea => subArea.name).join(' | ') : renderShortened(dataa.sub_areas.map(subArea => subArea.name).join(' | '))}</b>
                                {dataa.sub_areas.map(subArea => subArea.name).join(' | ').length > 10 && (
                                    <a onClick={toggleSubArea} style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}>
                                        {showAllSubArea ? ' See Less' : ' See More'}
                                    </a>
                                )}
                            </li>
                            {showDetails && showDetails ? <>
                                <li>
                                    Priority | <b>{dataa.priority}</b>
                                </li>
                                <li>
                                    Issue Date | <b>{dataa.ticketDate}</b>
                                </li>
                                {(dataa?.status === 'resolved' || dataa?.status === 'in process') &&
                                    <>
                                        <li>
                                            Response Date | <b>{dataa.responseDate}</b>
                                        </li>
                                        <li>
                                            Response | <b>{dataa.response}</b>
                                        </li>
                                    </>
                                }
                                <li>
                                    Status | <b>{dataa.status || 'Pending'}</b>
                                </li>

                            </> : null}

                            <button style={{ fontStyle: 'italic', background: 'white', border: '0px', marginLeft: '3px' }} onClick={() => { setShowDetails(!showDetails) }}> {showDetails ? 'See less' : 'See more'} </button>


                        </ul>
                    </Col>
                    <Col sm='6' xs='12'>
                        <div className='previousCardQuery'>
                            <label>Query:</label>
                            <div className='textArea'>
                                {dataa.query}
                            </div>
                        </div>
                    </Col>
                    {itmode == true ? <>
                        {dataa.status == 'resolved' ? null : <Col style={{ width: '100%', height: '100%', }} className='d-flex justify-content-end align-items-end'>
                            <Button variant="primary" className='mt-2 mx-2 py-1 px-4' onClick={toggle}>Update</Button>
                        </Col>}
                    </> : <>
                        {dataa.status == 'pending' ?
                            <Col style={{ width: '100%', height: '100%', }} className='d-flex justify-content-end align-items-end'>
                                <Button variant="primary" className='mt-2 mx-2 py-1 px-4' onClick={() => handleEditTicket(dataa.id)}>Edit</Button>
                            </Col>
                            : null}
                    </>}

                </Row>
            </CardBody>
        </Card>
    );
};

export default PreviousSupportTicketCard;
