import React, { useEffect, useState } from 'react';
import SupportAndTicketsScreen from '../../../Screens/GlobalUser/SupportAndTickets/support_and_tickets';
import HeroSection from '../../../Screens/GlobalUser/SupportAndTickets/Component/HeroSection/Hero_Section';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import AreaService from "../../../../api/areaService";
import PreviousSupportTicketCard from '../../../Screens/GlobalUser/SupportAndTickets/Component/PreviousSupportTicketCard';
import GenerateTicket from './Component/GenerateTicket';
import EditTicket from './Component/EditTicket';
import Loader1 from "../../../../CommonElements/Spinner/loader";

const AreaSupportTickets = () => {
    const [role, setRole] = useState(JSON.parse(localStorage.getItem('role')));
    const [previousTickets, setpreviousTickets] = useState([])
    const [generateTciketModal, setgenerateTciketModal] = useState(false)
    const [editTicketModal, seteditTicketModal] = useState(false)
    const handleEditTicketModal = () => seteditTicketModal(!editTicketModal)
    const handleGenerateModal = () => setgenerateTciketModal(!generateTciketModal);
    const [models, setmodels] = useState([]);
    const [areas, setareas] = useState()
    const [subAreaOptions, setsubAreaOptions] = useState([])
    const [selectedTicket, setselectedTicket] = useState()
    const [loader, setloader] = useState(true)
    const [dummyTickets, setdummyTickets] = useState(
        [
            {
                id: 1,
                "user_id": 1,
                "owner": "Meraj",
                "models": [
                    {
                        "module_id": 1,
                        "name": "Helmet"
                    },
                    {
                        "module_id": 2,
                        "name": "Vest"
                    },
                    {
                        "module_id": 5,
                        "name": "MMHE"
                    }
                ],
                "areas": [
                    {
                        "area_id": 11,
                        "area_name": "AO-6",
                        "owner": "Meraj"
                    },
                ],
                "sub_areas": [
                    {
                        "id": 76,
                        "name": "Roof Tops (Pulp Store)"
                    },
                    {
                        "id": 77,
                        "name": "Chemical store"
                    }
                ],
                "priority": "medium",
                "status": "pending",
                "query": "camera is not working",
                "response": "",
                "ticketDate": "2024-10-23 10:38:05 AM",
                "responseDate": ""
            },
            {
                id: 2,
                "user_id": 1,
                "owner": "Meraj",
                "models": [
                    {
                        "module_id": 1,
                        "name": "Helmet"
                    },
                    {
                        "module_id": 2,
                        "name": "Vest"
                    },

                    {
                        "module_id": 5,
                        "name": "MMHE"
                    }
                ],
                "areas": [
                    {
                        "area_id": 11,
                        "area_name": "AO-6",
                        "owner": "Meraj"
                    },
                ],
                "sub_areas": [
                    {
                        "id": 76,
                        "name": "Roof Tops (Pulp Store)"
                    },

                ],
                "priority": "medium",
                "status": "pending",
                "query": "camera is not working",
                "response": "",
                "ticketDate": "2024-10-23 10:41:54 AM",
                "responseDate": ""
            },
        ]
    )
    const generateTicket = (e) => {
        console.log(e, 'final')
        setdummyTickets([
            ...dummyTickets,
            e
        ])
    }
    const fetchAreaDashboardData = async () => {
        const res = await AreaService.getAreaUserTickets()
        const areaa = res?.data?.data?.areas.filter(e => e && e.area_name === 'AO-6');
        console.log(areaa[0], 'filtered area')
        setsubAreaOptions(areaa[0]?.sub_areas)
        setareas(areaa[0])
        setmodels(res.data.data.models)
        setpreviousTickets(res.data.data.tickets);
        setloader(false)
    }
    const handleEditTicket = (e) => {
        const data = dummyTickets.filter(a => a.id == e);
        const transformedData = data.map(ticket => ({
            ...ticket,
            models: ticket.models.map(model => ({
                module_id: model.module_id,
                module_name: model.name
            }))
        }));
        setselectedTicket(transformedData[0]);
        handleEditTicketModal();
    }
    const handleeditsubit = (e) => {
        const updatedTicket = e
        console.log(e, 'updated ticket')
        setdummyTickets(prevTickets => {
            // Filter out the old ticket with the matching ID
            const filteredTickets = prevTickets.filter(ticket => ticket.id !== updatedTicket.id);
            console.log(filteredTickets, 'after filteration')
            // Add the updated ticket to the filtered array
            return [...filteredTickets, updatedTicket];
        });
    }
    useEffect(() => {
        fetchAreaDashboardData()
    }, [])
    return (
        <>
            <br />
            <Container fluid={true}>
                <h5 style={{ fontSize: '22px' }}>Support and Tickets</h5>
                {loader ? <Loader1 /> : <>
                    <HeroSection role={role} generateTicket={handleGenerateModal} />
                    <h5>Previous Support Tickets</h5>
                    <Row>
                        {dummyTickets?.map((item, key) => (
                            <Col xl='12' xxl='6' key={key} >
                                <PreviousSupportTicketCard dataa={item} handleEditTicket={handleEditTicket} />
                            </Col>
                        ))}
                    </Row>
                    <EditTicket handleeditsubit={handleeditsubit} data={selectedTicket} show={editTicketModal} generateTicket={generateTicket} areas={areas} handleClose={handleEditTicketModal} modeloption={models} subAreaOptions={subAreaOptions} />
                    <GenerateTicket show={generateTciketModal} generateTicket={generateTicket} areas={areas} handleClose={handleGenerateModal} modeloption={models} subAreaOptions={subAreaOptions} />
                </>}
            </Container>
        </>
    );
}

export default AreaSupportTickets;
