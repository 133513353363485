import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import DetailCard from './Components/DetailsCard/Detail_Card';

const AreaAnalysis = () => {
    const cards=[1,200,3,40,50,600,7,8,900,10,200,60,40]
    return (
        <>
        <br />
        <Container fluid={true}>
        <h5>Area Analysis</h5>
             <Row>
                {cards.map((items)=>(
                <Col  xxl='3' xl='4' lg='6' md='6' >
                     <DetailCard items={items}/>
                </Col>
                ))}
             </Row>
        </Container>
        </>
    );
}

export default AreaAnalysis;
