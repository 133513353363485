import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Card, Container, Row, Col, CardBody, CardHeader } from 'react-bootstrap'
import Loader3 from '../../../../CommonElements/Spinner/loader3'
import SkeletonLoader from '../../../../CommonElements/Spinner/SkeletonLoader'
import { RecentOrderChart } from "./analytics_data_temp";
import ReactApexChart from "react-apexcharts";
import { ArrowDown, ArrowUp } from "react-feather";
import "../AIModelReports/custom.css";
import { getWeek } from "../../../../_helper/helper";
import { dummyJSON } from "../../../../Data/staticData/data";
import ApexInteractiveHeatmap from "./components/HeatMap";
import ProgressBars from "./components/ProgressBars";
import AlertsTrendChart from "./components/AlertsTrendChart";
import AreaService from "../../../../api/areaService";
import { getCurrentWeekNumber } from "../../../../utils/getCurrentWeekNumber";
import CameraService from "../../../../api/cameraService";
import { getCurrentWeekWithYear } from "../../../../utils/currentWeekWithYear";
import { analyticsPageService } from "../../../../api/analyticsPageService";
import { processHeatmapData } from "./helpers/processHeatmapData";

const LiveAnalyticsScreen = () => {
  const [loadingForDonut, setLoadingForDonut] = useState(true)
  const [loadingForBars, setLoadingForBars] = useState(false)
  const [heatmapLoader, setHeatmapLoader] = useState(false)
  const [connectivityLoader, setConnectivityLoader] = useState(true)
  const [acuuracyLoader, setAccuracyLoader] = useState(true)
  const [accuracyPercent, setAccuracyPercent] = useState()
  const [accuracySectionData, setAccuracySectionData] = useState({
    aiAccuracy: undefined,
    connectivity: undefined,
    highSeverityAlerts: undefined,
    maxAlerts: undefined
  })
  const [severityAlertsData, setSevirityAlertsData] = useState()
  const [severityLoader, setSevirityLoader] = useState(true)
  const [recentOrderChart, setRecentOrderChart] = useState(RecentOrderChart)
  const [DonutSeries, setDonutSeries] = useState()
  const [extractedData, setExtractedData] = useState({
    maxAlerts: 0,
    module: '',
    camera: '',
    owner: '',
    subArea: '',
  })
  console.log('extractedDataextractedData', extractedData)
  const [CurrentWeek, setCurrentWeek] = useState(undefined)
  const [heatmapData, setHeatmapData] = useState({
    areas: [],
    subAreas: [],
    areaOwner: [],
    data: [],
  });
  const [progressData, setProgressData] = useState(undefined);
  const [week, setWeek] = useState(undefined);
  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    fetchSevirityData()
    setHeatmapLoader(true)
    fetchAnalyticsBars()
    fetchAnlyticsData()
    fetchHeatmapData()
    // fetchConnectivity()
    modelAccuracy()

    //parse JSON data
    const parseJSONData = () => {
      const parsedData = JSON.parse(dummyJSON); // Parse the JSON
      const newSeries = parsedData.complianceData.series; // Extract the series data

      //top right section data 
      setAccuracySectionData(prevState => ({
        ...prevState,
        aiAccuracy: parsedData?.aiAccuracy,
        highSeverityAlerts: parsedData?.highSeverityAlerts,
        maxAlerts: parsedData?.maxAlerts,
      }));

      //progress bars
      setWeek(parsedData.week);
      // setProgressData(parsedData?.progressData);

      //heatmap
      // setHeatmapData(parsedData.heatmapData);

      // Set dynamic categories and series for chart
      setChartData({
        categories: parsedData.alertsTrendChart.categories,
        series: parsedData.alertsTrendChart.series,
      });



    };
    parseJSONData();

    // setLoader(true);
    const curr_date = new Date().toLocaleDateString("en-CA");
    // let unsubscribe = fetchAlerts(curr_date);
    const now = new Date();
    const year = now.getFullYear();
    const week = getWeek(now);
    const maxWeek = `${year}-W${String(week).padStart(2, "0")}`;
    setCurrentWeek(maxWeek);
    // return () => unsubscribe;
  }, []);
  // useEffect(() => {
  //   setLoader(true)
  //   CameraService.getAllLiveCameras()
  //     .then((res) => {
  //       const response = res?.data?.data;
  //       console.log('responsess', response)
  //       const conn_percentage =
  //         response?.length > 0
  //           ? Math.round(
  //             (response?.filter((i) => i?.active === true)?.length /
  //               response?.length) *
  //             100
  //           ).toFixed(0)
  //           : 0;

  //       setAccuracySectionData(prevState => ({
  //         ...prevState,
  //         connectivity: {
  //           value: conn_percentage,
  //           trend: conn_percentage > 50 ? "up" : "down",
  //           status: conn_percentage > 0 ? "All Systems operational" : "Systems are down"
  //         }
  //       }));
  //       setLoader(false)
  //     })
  //     .catch((e) => {
  //       setLoader(false)
  //       console.log(e);
  //     });
  // }, []);

  const tooltipContent = [
    { label: 'Max alerts by', value: 'AO-1 ( Adil )' },
    { label: 'Sub Area', value: 'Palletizing corridor' },
    { label: 'Alerts', value: '10' }
  ]

  //api calls
  async function fetchAnalyticsBars() {
    setLoadingForBars(true)
    try {
      const res = await AreaService.fetchAnalyticsProgressGbl()
      if (res.status == 200) {
        const updatedData = res?.data?.progressData.map(item => ({
          ...item,
          tooltipContent
        }));

        setProgressData(updatedData);
      }
      setLoadingForBars(false)
    } catch (err) {
      console.log('Analytics page, Progress bars section error', err)
      setLoadingForBars(false)
    }
  }
  async function fetchAnlyticsData() {
    try {
      const res = await AreaService.fetchAnalyticsPercentGbl()
      if (res.status == 200) {
        // Update the chart state with the new series data
        setRecentOrderChart((recentOrderChart) => ({
          ...recentOrderChart,
          series: res?.data?.complianceData?.series,  // Update series with parsed data
        }));
      }
      setLoadingForDonut(false)
    } catch (err) {
      console.log("Analytics page, Donut chart error", err)
      setLoadingForDonut(false)
    }

  }
  async function fetchSevirityData() {
    try {
      const res = await analyticsPageService.highSevrityF()
      if (res.status == 200) {
        console.log("res?.dataa", res?.data)
        setSevirityAlertsData(res?.data)
        
      }
      setSevirityLoader(false)
      
    } catch (err) {
      setSevirityLoader(false)
      console.log("High sevirity data Error", err)
      
    }

  }
  function processHeatmapDat(heatmapData) {
    const areas = heatmapData.areas;
    const areaOwner = heatmapData.areaOwner;
    const subAreas = heatmapData.subAreas;
    const data = heatmapData.data;
  
    let maxAlerts = 0;
    let module = '';
    let cameraIndex = -1;
  
    // Find the module with maximum alerts
    data.forEach((moduleData) => {
      moduleData.data.forEach((alerts, index) => {
        if (alerts > maxAlerts) {
          maxAlerts = alerts;
          module = moduleData.name;
          cameraIndex = index;
        }
      });
    });
  
    // Extract the corresponding area, owner, and sub-area
    const camera = areas[cameraIndex];
    const owner = areaOwner[cameraIndex];
    const subArea = subAreas[cameraIndex];
  
    return { maxAlerts, module, camera, owner, subArea };
  }
  async function fetchHeatmapData() {
    try {
      const res = await AreaService.fetchAnalyticsHeatmapGbl()
      if (res.status == 200) {
        setHeatmapData(res?.data?.heatmapData);
      }
      // Process the heatmap data to find the required values
      const extractedData = processHeatmapDat(res?.data?.heatmapData);
      console.log('extractedDataextractedData', extractedData)

      // Update the state with processed data
      setExtractedData(extractedData);
      setHeatmapLoader(false)
    } catch (err) {
      console.log("Analytics page, Heatmap chart error", err)
      setHeatmapLoader(false)
    }

  }
  // async function fetchConnectivity() {
  //   try {
  //     const res = await AreaService.fetchCameraConnectivity(41) //insert user id when users avaialble
  //     const newRes = res?.data?.data;
  //     const conn_percentage = Math.round((newRes?.active_cameras / newRes?.total_cameras) * 100).toFixed(0) || 0
  //     setAccuracySectionData(prevState => ({
  //       ...prevState,
  //       connectivity: {
  //         value: conn_percentage,
  //         trend: conn_percentage > 50 ? "up" : "down",
  //         status: conn_percentage > 0 ? "All Systems operational" : "Systems are down"
  //       }
  //     }));
  //     setConnectivityLoader(false)
  //   } catch (err) {
  //     setConnectivityLoader(false)
  //     console.log('Camera connectivity error', err)
  //   }
  // }
  useEffect(() => {
    setLoader(true)
    CameraService.getAllLiveCameras()
      .then((res) => {
        const response = res?.data?.data;
        const conn_percentage =
          response?.length > 0
            ? Math.round(
              (response?.filter((i) => i?.active === true)?.length /
                response?.length) *
              100
            ).toFixed(0)
            : 0;

        setAccuracySectionData(prevState => ({
          ...prevState,
          connectivity: {
            value: conn_percentage,
            trend: conn_percentage > 50 ? "up" : "down",
            status: conn_percentage > 0 ? "All Systems operational" : "Systems are down"
          }
        }));
        setConnectivityLoader(false)
      })
      .catch((e) => {
        setConnectivityLoader(false)
        console.log(e);
      });
  }, []);

  async function modelAccuracy() {
    const payload = {
      week: getCurrentWeekWithYear(),
    };
    try {
      const res = await AreaService.getModelAccuracyChart(payload);
      if (res?.statusText?.toLocaleLowerCase() == 'ok') {
        const models = res?.data?.totalAlertsChart;
        console.log("res?.datares?.data", res?.data)
        console.log(models, 'modelssss')
        const totalValue = models.reduce((sum, model) => sum + model.value, 0);
        // Calculate the average percentage
        const averagePercentage = (totalValue / models.length).toFixed(0) || 0;
        setAccuracyPercent(averagePercentage)
      }
      setAccuracyLoader(false)
    } catch (err) {
      console.log('Ai accuracy error'.err)
      setAccuracyLoader(false)
    }
  }

  console.log('getCurrentWeekWithYearrr', getCurrentWeekWithYear())
  console.log('accuracyPercent', accuracyPercent)
  const cardClass = `d-flex flex-column mb-0 gap-2 p-2 p-md-2 justify-content-center`

  return (
    <Fragment>
      <br />
      <Container fluid={true}>
        <Row>
          <Col md={6} xxl={4}>
            <Card style={{ height: "399px" }}>
              {

                loadingForDonut ? <span className="w-100 h-100 d-flex justify-content-center align-items-center position-absolute"><Loader3 /></span>
                  :
                  <>
                    <CardHeader>
                      <Row>
                        <Col xs="9">
                          <h5>Overall Compliance Score</h5>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="position-relative p-0">

                      <div className="">
                        <ReactApexChart
                          type="radialBar"
                          height={290}
                          options={recentOrderChart.options}
                          series={recentOrderChart.series}
                        />
                      </div>
                      <div
                        style={{ width: "100%" }}
                        className="d-flex justify-content-center"
                      >
                        <span
                          style={{ width: "80%", position: 'absolute', bottom: '15px' }}
                          className="text-center f-light"
                        >
                          Week {getCurrentWeekNumber()} Data
                        </span>
                      </div>
                    </CardBody>
                  </>
              }
            </Card>
          </Col>
          <Col md={6} xxl={4}>
            <ProgressBars progressData={progressData}
              week={week} loadingForBars={loadingForBars}
            />

          </Col>
          <Col
            xs='12' sm='mb-4' md={12} xxl={4}
            style={{ justifyContent: "center", alignItems: "center", margin: '0', padding: '0 12px' }}
            className="AiAccuracy-section"
          >
            <Col
              className="AiAccuracy-section-cards d-flex gap-sm-4"
              xs='12'
              style={{ justifyContent: "center", alignItems: "center", width: '100%', }}
            >
              {/* {mb-md-4 mb-xxl-2} */}
              <Card className="anlyticsTopCards" style={{ padding: "20px 10px", width: '50%' }}>
                <CardBody className={`${cardClass}`}>
                  <h6>AI Accuracy</h6>
                  {/* <h4 className="mb-0"> <ArrowDown color="red" size={20} />{85}%</h4> */}
                  {acuuracyLoader ? <><Loader3 /></> : <><h4 className="mb-0">
                    {accuracyPercent < 80 ? (
                      <ArrowDown color="red" size={20} />
                    ) : (
                      <ArrowUp color="green" size={20} />
                    )}
                    {accuracyPercent}%
                  </h4>
                    <span className="f-light">
                      Week {getCurrentWeekNumber()} Data
                    </span></>}
                  {/* <span className="f-light">Week 42 Data</span> */}
                </CardBody>
              </Card>
              <Card
                className="anlyticsTopCards"
                style={{ padding: "20px 10px", width: "50%" }}
              >
                <CardBody className={`${cardClass}`}>
                  <h6>Connectivity</h6>
                  {connectivityLoader ? <Loader3 /> : <>

                    <h4 className="mb-0">
                      {/* <ArrowUp color="green" size={20} />
                  100% */}
                      {accuracySectionData.connectivity?.trend === "up" ? (
                        <ArrowUp color="green" size={20} />
                      ) : (
                        <ArrowDown color="red" size={20} />
                      )}
                      {accuracySectionData.connectivity?.value}%
                    </h4>
                    {/* <span className="f-light">All systems operational</span> */}
                    <span className="f-light">
                      {accuracySectionData.connectivity?.status}
                    </span>
                  </>}
                </CardBody>
              </Card>
            </Col>
            <Card className="anlyticsBottomCard">
              <CardBody className={`w-100 d-flex justify-content-between p-2 p-md-2 `}>
               {heatmapLoader ? <div className="w-100 h-100 d-flex justify-content-center align-items-center"><Loader3 /></div>  :<><div className="d-flex flex-column w-50 gap-2 justify-content-center" style={{ padding: '20px 10px 20px 10px' }}>
                  <h6>High Severity Alerts</h6>
                  <h4 className="mb-0">
                    <ArrowUp color="green" size={20} />
                    {/* {accuracySectionData.highSeverityAlerts?.alerts} */}
                    {/* {severityAlertsData?.trend} */}
                    {extractedData?.maxAlerts}
                  </h4>
                  <span>
                    <span className="f-light ellipsis-text">
                      {/* Module: {accuracySectionData.highSeverityAlerts?.module} */}
                      {/* Module: {severityAlertsData?.module} */}
                      Module: {extractedData?.module}
                    </span>
                    <span className="f-light d-block ellipsis-text">
                      {/* Camera: {accuracySectionData.highSeverityAlerts?.camera} */}
                      {/* Camera: {severityAlertsData?.camera} */}
                      Camera: {extractedData?.camera?.split('-')[1]}
                    </span>
                  </span>
                </div>
                <div
                  className="d-flex flex-column w-50 gap-2 justify-content-center"
                  style={{ padding: "20px 15px" }}
                >
                  <h6 style={{ visibility: "hidden" }}>High Severity Alerts</h6>
                  <h4 className="mb-0">
                    {/* {accuracySectionData.maxAlerts?.area} */}
                    {/* {severityAlertsData?.area} */}
                    {extractedData?.camera}
                  </h4>
                  <span>
                    <span className="f-light d-block ellipsis-text">
                      {/* Owner: {accuracySectionData.maxAlerts?.owner} */}
                      {/* Owner: {severityAlertsData?.owner} */}
                      Owner: {extractedData?.owner}
                    </span>
                    <span
                      className="f-light d-block ellipsis-text"
                    >
                      {/* Sub Area: {accuracySectionData.maxAlerts?.subArea} */}
                      {/* Sub Area: {severityAlertsData?.sub_area} */}
                      Sub Area: N/A
                    </span>
                  </span>
                </div></>}
              </CardBody>
            </Card>

          </Col>
        </Row>
        <Row className=" ">
          <Col xs={12} lg={12} md={12} sm={12} xl={12}>
            <Card className="shadow-sm w-100">
              <Card.Header className="text-black">
                <h5 className="mb-0">Area Intensity Heatmap</h5>
              </Card.Header>
              <Card.Body className="p-0 p-md-3 p-lg-4 w-100">
                <div className="table-responsive w-100">
                  <div className="mb-4 w-100">
                    {heatmapLoader ? <><Loader3 /></> : <ApexInteractiveHeatmap
                      heatmapData={heatmapData}
                      moduleLength={progressData?.length}
                    />}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <AlertsTrendChart chartData={chartData} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LiveAnalyticsScreen;
